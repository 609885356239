import { createSlice } from '@reduxjs/toolkit';

export const meetingStartSlice = createSlice({
    name:'meetingStart',
    initialState:{
        loading: false,
        data: {},
        error: false,
    },
    reducers:{
        ACTIVE_MEETING_FETCH_START:(state)=>{
            state.loading=true
        },
        ADD_MEETING_START_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        ACTIVE_MEETING_START_FETCH_STOP:(state)=>{
            state.loading=false
        },
    }
});

export const { ACTIVE_MEETING_FETCH_START,ADD_MEETING_START_SUCCESS,ACTIVE_MEETING_START_FETCH_STOP }=meetingStartSlice.actions;
export default meetingStartSlice.reducer;