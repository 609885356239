import React, { Fragment, useEffect, useState } from "react";
import VideoJS from './VideoJS';
import PostLoveModal from 'react-bootstrap/Modal';
import { ADD_REPLY, POST_FETCH_COMMENT_ADDON, DELETE_REPLY, DELETE_COMMENT, DELETE_POST } from "../Reducer/postReducer";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../Network/API";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import PostShareModal from "./PostShareModal";
import UserList from "./UserList";
import { useDispatch } from "react-redux";
import { SINGLE_DELETE_COMMENT, SINGLE_POST_ADD_REPLY, SINGLE_POST_DELETE_REPLY, SINGLE_POST_FETCH_COMMENT_ADDON } from "../Reducer/singlePostReducer";
import { FPOST_FETCH_COMMENT_ADDON } from "../Reducer/friendProfileReducer";
import { ReactComponent as LoveIcon } from "../assets/svg/love.svg";
import { ReactComponent as LoveFillIcon } from "../assets/svg/love_fill.svg";
import { ReactComponent as CommentsIcon } from "../assets/svg/comments.svg";
import { ReactComponent as ShareIcon } from "../assets/svg/share.svg";
import { useTranslation } from "react-i18next";
toast.configure();

export default function PostNew(props) {
    const { t } = useTranslation()
    const data = props?.data;
    const [showMore, setShowMore] = useState(false);
    const myArr = JSON.parse(localStorage.getItem('user_data'));
    const [love_react, setLoveReact] = useState(false);
    const [unlove_react, setUnLoveReact] = useState(false);
    const [comment_love_react, setCommentLoveReact] = useState(false);
    const [reply_comment_delete, setReplyCommentDelete] = useState(false);
    const [reply_comment_id, setReplyCommentID] = useState(false);
    const [comment_love_react_add, setCommentLoveReactAdd] = useState(0);
    const [delete_post, setDeletePost] = useState(false);
    const [write_comment, setWriteComment] = useState('');
    const [reply_comment, setReplyComment] = useState('');
    const [share_comment, setShareComment] = useState(null);
    const [p_id_comment, setPIDComment] = useState(null);
    const [p_id_like, setPIDLike] = useState(0);
    const [p_id_com, setPIDCom] = useState(0);
    const [set_like, setLike] = useState(0);
    const [last_comments, setLastComments] = useState([]);
    const [set_comment, setComment] = useState(0);
    const [add_reply_comment, setAddReplyComment1] = useState(0);
    const [postId, setPostId] = useState(0);
    const [modalDialog, setModalDialog] = useState(false);
    const [modalDialogLoved, setModalDialogLoved] = useState(false);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [show, setShow] = useState(false);
    const [show_comment_box, setShowCommentBox] = useState(false);
    const [category_id, setCategoryId] = useState(0);
    const [report_note, setReportNote] = useState(0);
    const [categories, setCategories] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [is_comment_loading, setCommentLoading] = useState(false);
    const [reply_com_id, setReplyComID] = useState(0);
    const [comment_id, setCommentID] = useState(0);
    const [is_com_delete, setISComDelete] = useState(false);
    const [loadPostLove, setLoadPostLove] = useState(false);
    const [post_loved, setPostLoved] = useState([]);
    const [comment_page, setCommentPage] = useState(2);
    const [next_page_url, setNextPageURL] = useState(null);
    const [postView_modalDialog, setPostViewModalDialog] = useState(false);
    const [image_index, setImageIndex] = useState(null);
    const dispatch = useDispatch();
    const videoRef = React.useRef(null);

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    };


    const toggleModalLoved = () => {
        setModalDialogLoved(!modalDialogLoved)
    };

    const toggleModalSharePost = () => {
        setPostShareModalDialog(!post_modalDialog)

    };

    const togglePostViewModalDialog = (imageIndex) => {
        setPostViewModalDialog(!postView_modalDialog)
        setImageIndex(imageIndex)
        props.get_post_data(data);
        props.get_post_image_index(imageIndex);
        props.togglePostView(!postView_modalDialog);
    };

    // handle mouse enter
    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };
    // handle mouse leave
    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    useEffect(() => {
        setLastComments(data.last_comments)
        if (data.last_comments?.length > 10) {
            setNextPageURL('/post_comment_history?page=2');
        }


    }, [data]);


    function fetchPostCommentNext(postId) {

        setCommentLoading(true)

        API.get('post_comment_history/?page=' + comment_page + '&post=' + postId, {
            'offset': last_comments.length
        }).then((response) => {
            setCommentLoading(false)
            const paginator = response.data.data;
            if (paginator.get_posts.length) {
                setLastComments([...last_comments, ...paginator.get_posts])
                setNextPageURL(paginator.next_page_url)
                setCommentPage((comment_page + 1))
            }
        }).catch((error) => {
            setCommentLoading(false)
        });

    }




    const postLike = (post_id) => {
        setLoading(true)
        setLoveReact(true)
        setLike(1)
        setPIDLike(post_id)
        API.post("/v2/post_likes", {
            post_id: post_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            setLoveReact(true)
            if (response.data.success === true) {
                // toast.success('Post loved Successfully');
            } else {
                setLoveReact(false)
            }
        });
    }

    const postUnLike = (post_id) => {
        setLoading(true)
        setUnLoveReact(true)
        setLike(-1)
        setPIDLike(post_id)
        API.post("/v1/post_unlike", {
            post_id: post_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            setUnLoveReact(true)
            if (response.data.success === true) {
                // toast.success('Post unloved Successfully');
            } else {
                setUnLoveReact(false)
            }
        });
    }

    const toggleReply = (com_id) => {
        setShow(!show)
        setPIDCom(com_id)
    };

    const toggleComment = (post_id) => {
        setShowCommentBox(!show_comment_box)
        setPostId(post_id)
    };


    const commentLike = (post_id, comment_id) => {
        setLoading(true)
        setCommentLoveReact(true)
        setPIDCom(comment_id)
        setCommentLoveReactAdd(1)
        API.post("/comment_like", {
            post_id: post_id,
            comment_id: comment_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                // toast.success('Comment loved Successfully');
            }
        });
    }

    const commentsReplyDelete = (post_id, comment_id, comment_reply_id) => {
        setLoading(true)
        API.delete("/reply_comment_delete/" + comment_reply_id).then((response) => {
            if (response.data.success === true) {
                const payload = {
                    post_id: post_id,
                    comment_id: comment_id,
                    comment_reply_id: comment_reply_id,
                }
                setLoading(true)
                // setReplyCommentDelete(true)
                // setReplyCommentID(comment_reply_id)
                dispatch(DELETE_REPLY(payload))
                dispatch(SINGLE_POST_DELETE_REPLY(payload))

                toast.success('Comment deleted');
            }
        });
    }


    const handleReplyMessage = (p_id, cm_id) => {
        setLoading(true)
        setReplyComID(cm_id)
        API.post("/comment_reply", {
            post_id: p_id,
            comment_id: cm_id,
            comment: reply_comment,
        }).then((response) => {
            setLoading(false)
            setReplyComment('')
            if (response.data.success === true) {
                dispatch(ADD_REPLY(response.data.data))
                dispatch(SINGLE_POST_ADD_REPLY(response.data.data))
                // toast.success('comment reply sent successfully');
            }
        });
    }

    const isMessageEmpty = write_comment.trim() === '';
    const isReplyCommentEmpty = reply_comment.trim() === '';

    const submit_comment = (p_id) => {
        if (write_comment === null) {
            toast.error('Empty Comments');
            return false;
        }
        setLoading(true)
        setComment(1)
        API.post("/post_comments", {
            post_id: p_id,
            comment: write_comment,
        }).then((response) => {
            setLoading(false)
            setWriteComment("")
            if (response.data.success === true) {
                dispatch(POST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(FPOST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(SINGLE_POST_FETCH_COMMENT_ADDON(response.data.data))
                setPostId(p_id)
                // toast.success('Comment sent successfully');
            }
        });

    }

    const postDelete = (post_id) => {
        setLoading(true)
        API.delete("/posts/" + post_id).then((response) => {
            if (response.data.success === true) {
                setLoading(false)
                setDeletePost(true)
                setPIDLike(post_id)
                dispatch(DELETE_POST(post_id))
                toast.success('Post deleted');
            }
        });
    }

    const get_post_report = (post_id) => {
        toggleModal();
        API.get('post_report/' + post_id)
            .then((response) => {
                setCategories(response.data.data.categories)
                toggleModal();
            }).catch((error) => {
            });
    }

    const save_post = (p_id) => {
        setLoading(true)
        API.post("/save_friend_post", {
            post_id: p_id,
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                toast.success('Post Saved');
            }
        });
    }

    const commentsDelete = (comments_id, post_id) => {
        setLoading(true)
        API.delete("/comment_delete/" + comments_id).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                setISComDelete(true)
                setCommentID(comments_id)
                const comPayLod = {
                    comment_id: comments_id,
                    post_id: post_id,
                }
                dispatch(DELETE_COMMENT(comPayLod))
                dispatch(SINGLE_DELETE_COMMENT(comPayLod))
                setPostId(post_id)
                setComment(-1)
                toast.success('Comment deleted');
            }
        });
    }

    const share_post = (p_id) => {
        setPostId(p_id);
        toggleModalSharePost()
    }

    const send_report = (post_id) => {
        setLoading(true);
        API.post("/save_report", {
            post_id: post_id,
            category_id: parseInt(category_id),
            note: report_note,
        }).then((response) => {
            setLoading(false);
            if (response.data.success === true) {
                toast.success('Post reported');
                toggleModal();
            }
        }).catch((error) => {
            setLoading(false);
            toggleModal();
        });
    }

    const images =
        data.images?.length > 0 ?
            data.images.map((getImage, i) => (
                {
                    original: getImage.image,
                    thumbnail: getImage.image,
                }
            )) : "";

    let optionItems = categories?.map(category =>
        <option key={category.id} value={category.id}>{category.name}</option>
    );

    const love_report = (post_id) => {
        setLoadPostLove(true);
        API.get('post_love_details/' + post_id)
            .then((response) => {
                setPostLoved(response.data.data.data)
                toggleModalLoved();
            }).catch((error) => {
            });
    }

    return (

        <Fragment>
            <div key={data.id} className={`news-feed news-feed-post bg-white shadow-sm mb-3 ${p_id_like === data.id && delete_post ? 'd-none' : ''}`}>
                {
                data.post_type == 1 ?
                    <div className="Promoted_Post_content mb-3">
                        <i class="far fa-lightbulb"></i>
                        <span class="ms-1">{t('Suggested')}</span>
                    </div>
                : data.post_type == 2 ?
                        <div className="friend-rec-post mb-3">
                            <div className="post-header d-flex justify-content-between align-items-center mb-0">
                                <div className="d-flex align-items-center">
                                    <div className="image">
                                        <Link to={`/contact/${data.suggest_user_id}`}>
                                            <div
                                                className="user-picture-container rounded-circle"
                                                style={{ backgroundImage: `url(${data.suggest_user_image})` }}
                                            />
                                        </Link>
                                    </div>
                                    <div className="info ms-2">
                                        <span className="name">
                                            <Link to={`/contact/${data.suggest_user_id}`}>{data.suggest_user_name}</Link>
                                        </span>
                                        <span className="rection ms-1">{t('reacted on this')}</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    :data.post_type == 3 ?
                        <div className="Promoted_Post_content mb-3">
                            <i class="fa fa-bolt"></i>
                            <span class="ms-1">{t('Promoted')}</span>
                        </div>
                    :
                        ""
                    }
                <div className="post-header d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <div className="image">
                            <Link to={`/contact/${data.user_id}`}>
                                <div className="user-picture-container rounded-circle"
                                    style={{ backgroundImage: `url(${data.avatar})` }} />
                            </Link>
                        </div>
                        <div className="info ms-3">
                            <span className="name">
                                <Link to={`/contact/${data.user_id}`}>{data.post_by}
                                    {data.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                </Link>
                                {data.is_shared_post ?
                                    <span className=""><small className="fw-100 text-grey"> {t('shared')} </small> <Link to={`/contact/${data.root_user_id}`}>{data.root_user_name}</Link> <Link to={`/post/${data.share_post_id}`}><span className="fw-700 text-grey-900">{t('Post')}</span></Link></span> : ""}

                            </span>
                            <span className="small-text"><a href="javascript:void(0)">{data.post_time}</a></span>
                        </div>
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false"><i className="fa-solid fa-ellipsis" /></button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item d-flex align-items-center clickable" onClick={() => save_post(data.id)}><i
                                className="fa-solid fa-save" /> {t('Save Post')}</a></li>
                            <li><a className="dropdown-item d-flex align-items-center clickable" onClick={() => get_post_report(data.id)}><i
                                className="fa-solid fa-eye-slash" /> {t('Report Post')}</a></li>

                            {data.user_id === myArr['id'] ?
                                <li><a className="dropdown-item d-flex align-items-center clickable" onClick={() => postDelete(data.id)}><i
                                    className="fa-solid fa-trash-can" /> {t('Delete')} </a></li>
                                : ''}
                        </ul>
                    </div>
                </div>
                <div className="post-body">
                    <p dir="auto" className="post-text">
                        {data.is_shared_post ? data.self_details : showMore ? data.details : `${data.details?.substring(0, 450)}`}
                        <>
                            {data.details?.length < 450 ? '' :
                                <a className="clickable" style={{ fontSize: '12px', color: '#0d6efd', paddingLeft: '5px' }} onClick={() => setShowMore(!showMore)}>{showMore ? "" : "..." + t('See More')}</a>
                            }
                        </>
                    </p>
                    {
                        data.images?.length > 0 ?
                            <>
                                <div className="post-image">
                                    <div className="d-block p-0 mb-3">
                                        <div className="row ps-2 pe-2">
                                            {data.images?.map((postImage, i) => (
                                                <>
                                                    {data.images?.length <= 1 ?
                                                        <div onClick={() => togglePostViewModalDialog(i)} className={`col-12  p-1 text-center clickable`}>
                                                            <img src={postImage.image} />
                                                        </div> :
                                                        <>
                                                            {data.images?.length === 2 ?
                                                                <div className={`col-6  p-1`}>

                                                                    <div onClick={() => togglePostViewModalDialog(i)} className="frame_img_height frame_img clickable"
                                                                        style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                    {/*<Photo className="frame_img_height frame_img" key={i} {...postImage} {...i}/>*/}

                                                                </div> :
                                                                <>
                                                                    {data.images?.length === 3 ?
                                                                        <>

                                                                            <div className={`${i === 0 ? 'col-12' : 'col-6'}  p-1`}>
                                                                                <div onClick={() => togglePostViewModalDialog(i)} className={`${i === 0 ? 'frame_img_height3 ' : 'frame_img_height '} clickable frame_img rounded`}
                                                                                    style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                            </div>


                                                                        </>
                                                                        :
                                                                        <>
                                                                            {data.images?.length === 4 ?
                                                                                <div className={`col-6  p-1`}>
                                                                                    <div onClick={() => togglePostViewModalDialog(i)} className="frame_img_height frame_img clickable rounded"
                                                                                        style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    {data.images?.length >= 5 ?
                                                                                        <>
                                                                                            {i <= 4 ?
                                                                                                <div
                                                                                                    className={`${i <= 1 ? 'col-6' : 'col-4'}  p-1`}>
                                                                                                    <>
                                                                                                        {i === 4 && (data.images?.length - 5) !== 0 ?
                                                                                                            <a onClick={() => togglePostViewModalDialog(i)} className={'position-relative d-block clickable'}>
                                                                                                                <div
                                                                                                                    className={`${i <= 1 ? 'frame_img_height frame_img clickable rounded' : 'frame_img_height113 frame_img clickable rounded'}`}
                                                                                                                    style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                                                <span
                                                                                                                    className="img-count "><b>+{(data.images?.length - 5)}</b></span>
                                                                                                            </a>
                                                                                                            :
                                                                                                            <div onClick={() => togglePostViewModalDialog(i)}
                                                                                                                className={`${i <= 1 ? 'frame_img_height frame_img clickable rounded' : 'frame_img_height113 frame_img clickable rounded'}`}
                                                                                                                style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                                        }


                                                                                                    </>


                                                                                                </div> : ''
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>

                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </>
                            :
                            ''}

                    <div className="post-image" key={data.id}>

                        {data.videos?.length > 0 ?
                            <div>
                                {data.videos.map((getVideo, i) => (
                                    <VideoJS
                                        key={i}
                                        options={{
                                            controls: true,
                                            preload: 'metadata',
                                            height: '300',
                                            poster: getVideo.thumbnail,
                                            sources: [{
                                                src: getVideo.video_stream,
                                                type: 'video/mp4'
                                            }]
                                        }}
                                        enableScrollPause={true}
                                    />
                                ))}
                            </div>
                            : ''}
                    </div>

                    {data.url_details != null && data.url_details['image'] != null ?
                        <>
                            <a href={data.url_details['url']} target="_blank" rel="ugc" className="w-100">
                                {data.url_details['image'] != null ?
                                    <div className="post-image">
                                        <img className="img-fluid w-100" src={data.url_details['image']} alt="image" />
                                    </div>
                                    : ''
                                }


                                <div className="share-link-post">
                                    <div className="share-link-post-content">
                                        <h5 className="share-link-post-content-heading">
                                            {data.url_details['title']}
                                        </h5>

                                        <p className="share-link-post-content-disc">
                                            {data.url_details['description']}
                                        </p>
                                    </div>

                                </div>
                            </a>
                        </>
                        : ''
                    }

                    {data.is_shared_post ?
                        <div className={'border-1 shared-post-border'}>
                            <div className="post-header d-flex  align-items-center">
                                <div className="image">
                                    <Link to={`/contact/${data.root_user_id}`}>
                                        <div className="user-picture-container rounded-circle"
                                            style={{ backgroundImage: `url(${data.root_user_image})` }} />
                                    </Link>
                                </div>
                                <div className="info ms-3">
                                    <span className="name">

                                        <Link to={`/contact/${data.root_user_id}`}>{data.root_user_name}
                                            {data.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                        </Link>
                                    </span>
                                    <span className="small-text"><a href="javascript:void(0)">{data.root_post_time}</a></span>
                                </div>
                            </div>
                            <p dir="auto" className="post-text">
                                {data.details}
                            </p>
                        </div>
                        : ""}
                    <div className="post-meta-react-count d-flex justify-content-between align-items-center">
                        <div className="post-meta-react-count-love">
                            <a href="javascript:void(0)" onClick={() => love_report(data.id)}><i className="fa-solid fa-heart" /><span
                                className="number">{data.totalLikes + set_like} </span></a>
                        </div>
                        <div className="post-meta-react-count-comment">
                            <a href="javascript:void(0)">{data.totalComments + set_comment} {t('Comments')}</a>
                        </div>
                    </div>
                    <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                        <li className="post-react">
                            {data.is_like ?
                                <>
                                    <a className={'clickable'} onClick={() => postUnLike(data.id)}>{unlove_react && p_id_like === data.id ? <> <LoveIcon className="me-2" /> <span>{t('Love')}</span> </> : <> <LoveFillIcon className="me-2" /> <span>{t('Loved')}</span> </>}</a>
                                </>
                                :
                                <a className={'clickable'} onClick={() => postLike(data.id)}>{love_react && p_id_like === data.id ? <> <LoveFillIcon className="me-2" /> <span>{t('Loved')}</span> </> : <> <LoveIcon className="me-2" /> <span>{t('Love')}</span> </>}</a>
                            }
                        </li>
                        <li className="post-comment">
                            <a className={'clickable'} onClick={() => toggleComment(data.id)}><CommentsIcon className="me-2" /><span>{t('Comments')}</span></a>
                        </li>
                        <li className="post-share">
                            <a className={'clickable'} onClick={() => share_post(data.id)}><ShareIcon className="me-2" /><span>{t('Share')}</span></a>
                        </li>
                    </ul>
                    {last_comments?.length > 0 ?
                        <>
                            {/* .sort((a, b) => (a.id > b.id ? -1 : 1)) */}
                            <div style={{ height: '10px' }}></div>
                            {last_comments?.slice(0, 10)?.map((getcomment, i) => (
                                <>
                                    <div key={i}
                                        className={`post-comment-list ${is_com_delete && comment_id === getcomment.id ? 'd-none' : ''} `}
                                        id={"comments_" + getcomment.post_id}>
                                        <div className="comment-list d-flex justify-content-between">
                                            <div className="comment-image">
                                                <Link to={`/contact/${getcomment.comment_user_id}`}>
                                                    <div className="user-picture-container rounded-circle"
                                                        style={{ backgroundImage: `url(${getcomment.avatar})` }} />
                                                </Link>
                                            </div>
                                            <div className="comment-list-meta">
                                                <div className="comment-info">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="name">
                                                            <Link to={`/contact/${getcomment.comment_user_id}`}>{getcomment.comment_by}</Link>
                                                            {getcomment.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                        </div>
                                                        <a className="time">{getcomment.comment_time}</a>
                                                    </div>
                                                    <p dir="auto">{getcomment.comment}</p>
                                                </div>
                                                <ul className="comment-react">
                                                    <li className="post-react">
                                                        {getcomment.is_like ?
                                                            <a className={'clickable'}>
                                                                <LoveIcon className="comments-react-icon me-1" />
                                                                <span className="number"> {getcomment.total_likes} </span> <span>{t('Love')}</span>
                                                            </a>
                                                            :
                                                            <a className={'clickable'} onClick={() => commentLike(data.id, getcomment.id)}>
                                                                {p_id_com === getcomment.id && comment_love_react ? <LoveFillIcon className="comments-react-icon me-1" /> : <LoveIcon className="comments-react-icon me-1" />}
                                                                <span className="number"> {p_id_com === getcomment.id && comment_love_react ? getcomment.total_likes + comment_love_react_add : getcomment.total_likes > 0 ? getcomment.total_likes : ''} </span> <span>{t('Love')}</span>
                                                            </a>
                                                        }

                                                    </li>
                                                    <li className="post-comment clickable">
                                                        <a onClick={() => toggleReply(getcomment.id)}><CommentsIcon className="comments-react-icon" /> {getcomment.comment_reply?.length > 0 ? `${getcomment.comment_reply?.length}` : ''} <span>{t('Reply')}</span>
                                                            <span className="number me-1"> </span>
                                                        </a>
                                                        <span onClick={() => toggleReply(getcomment.id)} className="post-comment-replay-icon"><i className="fa-solid fa-angle-down post-comment-replay-icon" /></span>
                                                    </li>
                                                </ul>
                                            </div>
                                            {getcomment.is_deletable === 1 ?
                                                <div className="comment-button">
                                                    <div className="dropdown">
                                                        <button className="dropdown-toggle" type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false"><i
                                                                className="fa-solid fa-ellipsis" /></button>
                                                        <ul className="dropdown-menu">

                                                            <li><a className="dropdown-item d-flex align-items-center clickable"
                                                                onClick={() => commentsDelete(getcomment.id, data.id)}><i
                                                                    className="fa-solid fa-trash-can" /> {t('Delete')} </a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>

                                    {getcomment.comment_reply?.length > 0 ?
                                        <>
                                            {/* .sort((a, b) => (a.id > b.id ? -1 : 1)) */}
                                            {getcomment.comment_reply?.map((reply, i) => (
                                                <>
                                                    <div key={Math.random()} id={`reply${reply.id}`}
                                                        className={`post-comment-list post-comment-replay ${p_id_com === getcomment.id && show ? '' : 'd-none'} ${reply_comment_delete && reply_comment_id === reply.id ? 'd-none' : ''}`}>
                                                        <div className="comment-list d-flex justify-content-between">
                                                            <div className="comment-image">
                                                                <Link to={`/contact/${reply.comment_user_id}`}>
                                                                    <div
                                                                        className="user-picture-container rounded-circle"
                                                                        style={{ backgroundImage: `url(${reply.avatar})` }} />
                                                                </Link>
                                                            </div>
                                                            <div className="comment-list-meta">
                                                                <div className="comment-info">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="name">
                                                                            <Link
                                                                                to={`/contact/${reply.comment_user_id}`}>{reply.comment_by}</Link>
                                                                            {reply.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                                        </div>
                                                                        <span className="time"
                                                                        >{reply.comment_time}</span>
                                                                    </div>
                                                                    <p dir="auto">{reply.comment}</p>
                                                                </div>
                                                            </div>
                                                            {reply.is_deletable ?
                                                                <div className="comment-button">
                                                                    <div className="dropdown">
                                                                        <button className="dropdown-toggle"
                                                                            type="button"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false">
                                                                            <i
                                                                                className="fa-solid fa-ellipsis" />
                                                                        </button>
                                                                        <ul className="dropdown-menu">

                                                                            <li><span
                                                                                onClick={() => commentsReplyDelete(reply.post_id, reply.comment_id, reply.id)}
                                                                                className="dropdown-item d-flex align-items-center clickable"
                                                                            ><i
                                                                                    className="fa-solid fa-trash-can" /> {t('Delete')}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> : ""
                                                            }
                                                        </div>
                                                    </div>


                                                </>

                                            ))}


                                        </> : ""
                                    }
                                    <div
                                        className={`post-comment-list post-comment-replay  ${p_id_com === getcomment.id && show ? '' : 'd-none'}`}>
                                        <form className="post-footer d-flex justify-content-between">
                                            <div className="footer-image">
                                                <Link to="javascript:void(0)">
                                                    <div
                                                        className="user-picture-container rounded-circle"
                                                        style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                                                </Link>
                                            </div>
                                            <div className="form-group post-footer-comment-form">
                                                <textarea name="message" className="form-control"
                                                    placeholder={t('Post your reply')}
                                                    defaultValue={'@' + getcomment.comment_by + ' '} value={reply_comment} onChange={(e) => setReplyComment(e.target.value)} />
                                                <label className="post-footer-comment-icon">
                                                    {/* <a href="javascript:void(0)"><i
                                                        className="fa-regular fa-face-smile" /></a> */}
                                                    <button type="button" onClick={() => handleReplyMessage(data.id, getcomment.id)} className={isReplyCommentEmpty ? 'post-footer-comment-submit bg-secondary' : 'post-footer-comment-submit'} disabled={isReplyCommentEmpty}>{t('Post')}  {isLoading && reply_com_id === getcomment.id ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm ml-5"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                    </button>
                                                </label>
                                            </div>
                                        </form>
                                    </div>

                                </>
                            ))}
                            {data.last_comments?.length > 10 && next_page_url != null ?
                                <div className="view-comment-text text-center">
                                    <a className={'clickable'} onClick={() => fetchPostCommentNext(data.id)}>View more{is_comment_loading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}</a>
                                </div>
                                : ''}
                        </>
                        : ''
                    }
                </div>
                <div
                    className={`post-footer d-flex justify-content-between `}>
                    <div className="footer-image">
                        <a href="javascript:void(0)">
                            <div className="user-picture-container rounded-circle"
                                style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                        </a>
                    </div>
                    <div className="form-group post-footer-comment-form">
                        <textarea name="message" className="form-control" placeholder={t('Post your comments')}
                            value={write_comment} onChange={(e) => setWriteComment(e.target.value)}
                        />
                        <label className="post-footer-comment-icon">
                            {/* <a href="javascript:void(0)"><i className="fa-regular fa-face-smile" /></a> */}
                            <button type="button" onClick={() => submit_comment(data.id)} className={isMessageEmpty ? 'post-footer-comment-submit bg-secondary' : 'post-footer-comment-submit'} disabled={isMessageEmpty}>{t('Post')}
                            </button>
                        </label>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalDialog}
                size=""
                toggle={toggleModal}
            >
                <ModalHeader>
                    {t('Report')}
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label className="form-label">{t('Category')}</label>
                        <select onChange={(e) => setCategoryId(e.target.value)} className="form-control" id="category" name="category_id">{optionItems}</select>
                    </div>
                    <div className="form-group mt-2">
                        <label className="form-label">{t('Your Message')}</label>
                        <textarea onChange={(e) => setReportNote(e.target.value)} className="form-control" id="note" name="report_note" placeholder={t('Write Your Message')}></textarea>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => send_report(data.id)} className="btn btn-primary" >{t('Submit')} {isLoading ? (
                        <span
                            className="spinner-border spinner-border-sm ml-5"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    ) : (
                        <span></span>
                    )}</button>
                    <button className="btn" onClick={toggleModal}>{t('Cancel')}</button>
                </ModalFooter>
            </Modal>

            <PostLoveModal
                show={modalDialogLoved}
                size=""
                onHide={toggleModalLoved}
            >
                <PostLoveModal.Header closeButton>
                    <div className="post-meta-react-count-love">
                        <a href="javascript:void(0)">
                            <i className="fa-solid fa-heart"></i>
                            <span className="number">{data.totalLikes}</span>
                        </a>
                    </div>

                </PostLoveModal.Header>

                <PostLoveModal.Body className={`custom-scroll pt-2`}>
                    <div className="recent-chat">
                        <ul className="list-group list-group-flush">
                            {post_loved?.map((user, index) => (
                                <UserList user={user} />
                            ))
                            }

                        </ul>
                    </div>
                </PostLoveModal.Body>
            </PostLoveModal>

            <PostShareModal post_data={data} isModalVisible={post_modalDialog} handleShowB={() => setPostShareModalDialog(false)} postId={postId} />
            {/* <PostView data={data} isModalVisible={postView_modalDialog} handleShowB={() => setPostViewModalDialog(false)} imageIndex={image_index} /> */}
        </Fragment>
    )
}