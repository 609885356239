import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../../Network/API";



export default function ShareMeeting(props) {
    const [data, setData] = useState(null);
  const [name, setName] = useState(null);
  const [isLoading, setLoad] = useState(false);
  const [user_select_data, setUserSelectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user_id, setUserId] = useState([]);
  const [meeting_link, setMeetingLink] = useState(null);
  const domainName = window.location.origin;
    
    const myArr = JSON.parse(localStorage.getItem('user_data'));
    function myFunction() {
        var copyText = document.getElementsByClassName("myInput");
        navigator.clipboard.writeText(copyText[0].textContent+'\n\n'+copyText[1].textContent+'\n'+copyText[2].textContent+'\n'+copyText[3].textContent+'\n\n'+copyText[4].textContent+'\n'+copyText[5].textContent+'\n'+copyText[6].textContent);
      }

      const clear_search=()=>{
        setLoad(false)
      }
    
      function search_friend(e) {
        setName(e)
        if (e !== "" || e !== null) {
          setLoad(true)
          API.get(`/v3/search_friend?q=${e}`).then((response) => {
            setLoad(true)
            setData(response.data.data)
          }).catch((error) => {
            setLoad(false)
          });
        }
    
      }
    
      const AddUserId = (event) => {
        if (event.target.checked) {
          if (user_id.indexOf(parseInt(event.target.value)) === -1) {
            user_id.push(parseInt(event.target.value))
            user_select_data.push({
              'id':parseInt(event.target.value),
              'name':event.target.title,
            })
          }
        } else {
         removeID(parseInt(event.target.value))
        }
        setLoad(false)
      }
    
    const removeUser=(sl,u_id)=>{
       setUserSelectData(user_select_data.filter(a =>
           a.id !== u_id
       ))
       removeID(u_id)
    }

    const AddGroupId = (event) => {
      if (event.target.checked) {
        if (user_id.indexOf(event.target.value) === -1) {
          user_id.push(event.target.value)
          user_select_data.push({
            'id': event.target.value,
            'name': event.target.title,
          })
        }
      } else {
        removeID(event.target.value)
      }
      setLoad(false)
    }
    
      const removeID = (url) => {
        const index = user_id.indexOf(url);
        if (index > -1) {
          user_id.splice(index, 1);
    
        }
        setUserId(user_id)
    
      }
    
      
      const checkedState=(id)=>{
        return user_id.find((element) => {
          return element === id;
        })
      }

      const _schdeule_done=()=>{
        window.location.href = "/meeting";
    }

      const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);
        var copyText = document.getElementsByClassName("myInput");
        var data = {
          'user_id': user_id,
          'room_id': props.meetingRoom?.room_name,
          'message': copyText[0].textContent+'\n\n'+copyText[1].textContent+'\n'+copyText[2].textContent+'\n'+copyText[3].textContent+'\n\n'+copyText[4].textContent+'\n'+copyText[5].textContent+'\n'+copyText[6].textContent,
          'message_type': 10,
        }
        API.post('/v3/meeting_share', data).then(response => {
          setLoading(false);
          if (response.data.success === true) {
              toast.success('Meeting shared');
              props.handleShowB()
          }
        }).catch((error) => {
          setLoading(false);
          console.log(error.message)
          toast.error('Please try again.');
        });
      }

      const _start_meeting=(meeting_id)=>{
        if(meeting_id){
          window.location.href = "/meeting/"+meeting_id;
        }else{
          toast.error("Please enter meeting id");
        }
      }

    return (
    <>
        <Modal
            isOpen={props.isModalVisible}
            size=""
            toggle={props.handleShowB}
            className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
        >
          <ModalHeader className="bg-light" toggle={props.handleShow} id={'staticBackdropLabel'}>
          Invite new user
          </ModalHeader>
          
            <ModalBody className="bg-light">
              <div className="meeting-schedule-popup">
              <form onSubmit={handleSubmit}>
              <div className="meeting-invite-popup">

                <div className="form-control" style={{border:'2px solid #ccc',letterSpacing:'.03em',color:'#131619'}}>
                <p className="m-0 p-0 text-dark myInput">{myArr?.name} is inviting you to a scheduled connects meeting.</p>
                <br/>
                <p className="m-0 p-0 text-dark myInput">Topic: {props.meetingRoom?.room_title}</p>
                <p className="m-0 p-0 text-dark myInput">Time: {new Date(props.meetingRoom?.start_date).toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })}</p>
                <p className="m-0 p-0 text-dark myInput">Timezone: {props.meetingRoom?.time_zone}</p>
                <br/>
                <p className="m-0 p-0 text-dark myInput">Join Connect Meeting</p>
                <p className="m-0 p-0 text-dark myInput">{domainName}/meeting/{props.meetingRoom?.room_name}</p>
                <p className="m-0 p-0 text-dark myInput">Meeting ID: {props.meetingRoom?.room_name}</p>
                </div>

                <button type="button" className="btn btn-primary btn-sm my-3" onClick={myFunction}>Copy Meeting Invitation</button>


                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Invite Contact</label>
                    <div className="dropdown w-100" id="i_Friends">
                      <input onChange={(e) => search_friend(e.target.value)} className="btn bg-white  text-secondary text-start invite_f_dropdown dropdown-toggle w-100" aria-expanded="false" placeholder="Search Contact"  />

                      {name !== '' && isLoading ?
                          <div className="search-item shadow-sm" id="search-item" style={{display: 'block',height:'auto'}}>
                            <div className="search-list">
                              <div className="recent-chat">
                                <ul className="list-group list-group-flush">
                                  {data?.users?.map((value, index) => (
                                      <li className="list-group-item " key={index}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="reaction-pp d-flex align-items-center">
                                            <div className="recent-chat-user-img me-2">
                                              <a href={`/contact/${value.user_id}`}>
                                                <div className="user-picture-container rounded-circle"
                                                     style={{background: `url("${value.avatar}")`}}/>
                                              </a>
                                            </div>
                                            <div className="name">
                                              <a href={`/contact/${value.user_id}`}
                                                 className="text-dark">{value.name}</a>
                                              <p style={{fontSize: '12px'}}>{value.email}</p>
                                            </div>
                                          </div>

                                          <div className="reaction-btn">

                                            <input defaultChecked={checkedState(value.user_id)} name={`user[${value.user_id}]`} className="form-check-input"
                                                   title={value.name} value={value.user_id} type="checkbox"
                                                    onClick={(e) => AddUserId(e)}
                                                   id={`custom-checkbox-${index}`}/>

                                          </div>
                                        </div>
                                      </li>
                                  ))}

                                      <li>
                                        <b className="fs-12 me-2 ms-2">Other Contact Groups</b>
                                      </li>

                                    {data?.groups?.map((value, index) => (
                                      <li className="list-group-item " key={index}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="reaction-pp d-flex align-items-center">
                                            <div className="recent-chat-user-img me-2">
                                              <a href={`#`}>
                                                <div className="user-picture-container rounded-circle"
                                                  style={{ background: `url("${value.avatar}")` }} />
                                              </a>
                                            </div>
                                            <div className="name">
                                              <a href={`#`}
                                                className="text-dark">{value.name}</a>
                                              <p style={{ fontSize: '12px' }}>Hey there! I am using connects</p>
                                            </div>
                                          </div>

                                          <div className="reaction-btn">
                                            <input  name={`group[${value.id}]`} className="form-check-input"
                                              title={value.name} value={value.id} type="checkbox"
                                              onClick={(e) => AddGroupId(e)}
                                              id={`custom-checkbox-${index}`} />
                                          </div>
                                        </div>
                                      </li>
                                    ))}

                              </ul>
                            </div>
                          </div>
                        </div>
                        : ''
                      }



                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {user_select_data?.length > 0 ?

                    user_select_data.map((username, i) =>
                      <>
                        <span key={i} className="badge bg-white shadow-sm rounded-pill text-dark">{username['name']} <i className="me-1 fas fa-times-circle" onClick={() => removeUser(i, username['id'])} /></span>
                      </>
                    )
                    : ''
                  }
                </div>
              </div>
            <ModalFooter>
                <button type="submit" className="btn btn-primary btn-sm">Invite</button>
                <button type="button" className="btn btn-light btn-sm" onClick={props.handleShowB}>Cancel</button>
            </ModalFooter>
            </form>
          </div>
          </ModalBody>
          
        </Modal>
    </>)
}