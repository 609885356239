import API from "../../Network/API";
import ContentLoader from "react-content-loader";
import React, { Component, Fragment, useEffect, useRef, useState, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import SearchFriend from "./SearchFriend";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NewChatList from "./NewChatList";
const user1 = JSON.parse(localStorage.getItem("user_data"));


export default function MessageNav() {
    const {t} = useTranslation()
    const [demo_data, setData] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }]);
    const [progress, setProgress] = useState(false);
    const navigate = useNavigate();
    const [InboxLists, setInboxList] = useState([]);
    const location = useLocation();
    const [search_data, setSearchData] = useState([]);
    const [chatlist_data, setChatListData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [toggleNewChat, setToggleNewChat] = useState(false);
    // users.filter(({id}) => (id !== user.id))
    const _start_thread = (id, is_group) => {
        if (is_group == 0) {
            navigate("/messages/thread/" + id);
        } else {
            navigate("/messages/group/" + id);
        }

    }

    const clear_search = () => {
        setLoading(false)
    }

    const fetch_search = (value) => {
        if (value === "" || value === null) {
            setLoading(false)
            return false
        } else {
            API.get('v3/search_friend?q=' + value).then((response) => {
                setLoading(true)
                if (response.data.success === true) {
                    setSearchData(response.data.data)
                   
                } else {
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false)
            });
        }

    }
    const selector = useSelector((state) => state.messageReducer);
    const InboxList = selector?.users;
    const strAscending = [...InboxList].sort((a, b) =>
        a.last_message_id < b.last_message_id ? 1 : -1,
    );
    // const MessagesList = selector?.users.sort((a, b) => a.last_message_id > b.last_message_id ? 1:-1);

    const showNewChat=()=>{
        setToggleNewChat(!toggleNewChat)
    }

    return (
        <>
                { toggleNewChat?
                <NewChatList  handleShowB={() => setToggleNewChat(false)}/>
                
                :
            <div className="all-chater-list bg-white margin-top-minex">
                <div className="msg-search-heading d-flex justify-content-between align-items-center mb-2 mt-2">
                    <h6>{t('Messages')}</h6>
                    <div className="msg-search-heading-right d-flex align-items-center">
                        <div id="add-group-icon">
                            <a id="show-group-crate" className="show-group-crate" href="javascript:void(0)" onClick={showNewChat}> <i className="fa-solid fa-user-plus" /></a>
                        </div>
                        {/* <div className="global-dropdown">
                            <div className="dropdown">
                                <button className="dropdown-toggle dropdown-bg-transparent" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item align-items-center" href="javascript:void(0)">
                                        Create Group</a></li>
                                    <li><a className="dropdown-item  align-items-center" href="javascript:void(0)">Settings</a></li>

                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="message-people-srch">
                    <div className="messages-search-box">
                        <form>
                            <input
                                type="text"
                                className="input-search"
                                placeholder={t('Search Contacts')}
                                onChange={(e) => fetch_search(e.target.value)}
                            />
                            {isLoading ?
                                <button type="button" onClick={() => clear_search()}>
                                    <i className="fa-solid fa-remove" />
                                </button>
                                :
                                <button type="button">
                                    <i className="fa-solid fa-magnifying-glass" />
                                </button>
                            }
                            {isLoading && (
                                <SearchFriend data={search_data} />
                            )}
                        </form>

                    </div>
                </div>
                <div className="mesg-peple">
                    <ul className="messages-list">

                        {selector.loading && demo_data?.map((post, index) => (
                            <div key={index}>
                                <li>

                                    <div className="usr-msg-details d-flex align-items-center">
                                        <div className="usr-ms-img flex-grow-1">
                                            <ContentLoader
                                                speed={3}
                                                width={250}
                                                height={80}
                                                viewBox="0 0 250 80"
                                                backgroundColor="#dfdddd"
                                                foregroundColor="#ebebeb"

                                            >
                                                <circle cx="53" cy="57" r="24" className="user-picture-container rounded-circle" />
                                                <rect x="81" y="43" rx="0" ry="0" width="327" height="8" />
                                                <rect x="82" y="57" rx="0" ry="0" width="271" height="7" />
                                            </ContentLoader>

                                        </div>
                                    </div>
                                </li>
                            </div>
                        ))}

                        {!selector.loading && InboxList?.length > 0 ?
                            strAscending?.map((value, index) => (
                                <div key={index}>
                                    <li className={`${location.pathname === '/messages/thread/' + value.to_id ? 'active' : location.pathname === '/messages/group/' + value.to_id ? 'active' : ''}`} onClick={() => _start_thread(value.to_id, value.is_group)}>

                                        <div className="usr-msg-details d-flex align-items-center">
                                            <div className="usr-ms-img flex-grow-1">

                                                <div className="d-flex align-items-center">
                                                    <div
                                                        className="user-picture-container rounded-circle"
                                                        style={{ backgroundImage: `url(${value.image})` }}
                                                    />
                                                    <div className="usr-mg-info">
                                                        <h3>
                                                            {value.name}

                                                           <>
                                                           {value.is_group==0?
                                                           <>
                                                           {value.is_online == '1' ?
                                                                <span className="ms-1 people-active "><i className="fa-solid fa-circle" /></span>
                                                                :
                                                                <span className="ms-1 people-active people-inactive"><i className="fa-solid fa-circle"></i></span>
                                                            }
                                                           </>
                                                            
                                                        :''}
                                                           </>

                                                        </h3>
                                                        <p className={`${value.unread_count > 0 ? 'unseen-color' : ''}`}>{value.message?.length > 23 ? `${value.message.substring(0, 22)}...` : value.message}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="msg-seen-time">
                                                <div className="posted_time">{value.created_at}</div>
                                                {value.unread_count > 0 ?
                                                    <div className="msg-notifc">{value.unread_count}</div>
                                                    :
                                                    <div className="seen-unseen-icon">
                                                        {value.seen_at != null ?
                                                            <i className="fa-solid fa-check-double"
                                                                style={{ color: '#23d2e2' }} />
                                                            :
                                                            <i className="fa-solid fa-check-double" />
                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                </div>
                            ))
                            : ''}
                        {/* messages list dynamic end */}

                    </ul>
                </div>
            </div>
              }                                  
            
           
        </>
    );
}