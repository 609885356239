import {Fragment} from "react";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/svg/home.svg";
import { ReactComponent as MeetingIcon } from "../../assets/svg/meeting.svg";
import { ReactComponent as ProfileIcon } from "../../assets/svg/profile.svg";
import { ReactComponent as ChatIcon } from "../../assets/svg/chats.svg";
import { ReactComponent as NotificationIcon } from "../../assets/svg/notification.svg";
import { useTranslation } from "react-i18next";

export default function MobileNav(){
    const {t} = useTranslation()
    return(
        <Fragment>
            <div className="d-flex justify-content-between align-items-center">
            <Link to={'/'} className="flex-grow-1 text-center mobile-bottom-nav-item">
                    <HomeIcon />
                    <p>{t('Home')}</p>
                </Link>
                <Link to={'/meeting'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <MeetingIcon />
                    <p>{t('Meeting')}</p>
                </Link>
                <Link to={'/messages'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <ChatIcon />
                    <p>{t('Messages')}</p>
                </Link>
                <Link to={'/notifications'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <NotificationIcon />
                    <p>{t('Notifications')}</p>
                </Link>
                <Link to={'/me'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <ProfileIcon />
                    <p>{t('Profile')}</p>
                </Link>
            </div>
        </Fragment>
    )
}