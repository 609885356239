import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import userReducer from './userReducer';
import notifyReducer from './notifyReducer';
import messageReducer from './messageReducer';
import userInboxReducer from './userInboxReducer';
import inboxAttachment from './inboxAttachment';
import postReducer from './postReducer';
import settingsReducer from './settingsReducer';
import friendProfileReducer from './friendProfileReducer';
import ChatListId from './ChatListId';
import groupMemberSlice from './groupMemberReducer';
import roleSlice from './memberRoleReducer';
import UserSelfReducer from './UserSelfReducer';
import singlePostReducer from './singlePostReducer';
import authSlice from './authSlice';
import TimeZoneSlice from './TimeZoneSlice';
import suggestedVideoReducer from './suggestedVideoReducer';
import MyProfileSlice from './MyProfileSlice';
import storySlice from './storySlice';
import ActiveNotifyReducer from './ActiveNotifyReducer';
import MeetingStartReducer from './MeetingStartReducer';
import suggestedUserReducer from './suggestedUserReducer';
import NewFeaturesReducer from './NewFeaturesReducer';
import UpcomingMeetingReducer from './UpcomingMeetingReducer';
export default combineReducers({
    homeReducer:homeReducer,
    userReducer:userReducer,
    notifyReducer:notifyReducer,
    messageReducer:messageReducer,
    chatLogs:userInboxReducer,
    inboxAttachment:inboxAttachment,
    postReducer:postReducer,
    settingsReducer:settingsReducer,
    friendProfileReducer:friendProfileReducer,
    ChatListId:ChatListId,
    members:groupMemberSlice,
    role:roleSlice,
    userSelf:UserSelfReducer,
    singlePostReducer:singlePostReducer,
    authReducer:authSlice,
    timeZoneReducer:TimeZoneSlice,
    suggestedVideoReducer:suggestedVideoReducer,
    myProfileReducer:MyProfileSlice,
    storyReducer:storySlice,
    activeNotifyReducer:ActiveNotifyReducer,
    meetingStartReducer:MeetingStartReducer,
    suggestedUserReducer:suggestedUserReducer,
    newFeaturesReducer:NewFeaturesReducer,
    upcomingMeetingReducer:UpcomingMeetingReducer,
});