import API from "../Network/API";
import React, {Component, Fragment, useEffect, useState, memo} from "react";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Placeholder1 from "../Network/Placeholder1";
import PlaceholderSuggestFriend from "../Network/PlaceholderSuggestFriend";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
toast.configure();

export default function ConnectSuggest() {
    const {t} = useTranslation()
    const [users, setUsers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [div_id, setDivID] = useState(0);
    const [progress, setProgress] = useState(false);
    const [demo_data, setDatas] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}]);
    const selector = useSelector((state) => state.suggestedUserReducer)

    const _sendFriendRequest =(id)=>{
       setLoading(true)
        setDivID(id)  //user id
        API.post("/friend_request",{
            user_id: id,
        }).then((response) => {
            setLoading(false)

            if (response.data.success === true) {
                toast.success("Request sent");
               
            }
        }).catch((error) => {
            if( error.response ){
                toast.success("something went wrong!");
            }
        });
    }

    return (
        <>
            <h4 className="px-2">{t('Contact Suggestions')}</h4>
            <div className="">
                <div className="Account">

                    <ul className="list-group list-group-flush">
                    {selector?.loading && demo_data?.map((post, i) => (
                        <li key={i} className="list-group-item widget-item-list ">
                        <PlaceholderSuggestFriend  key={i} backgroundColor="#ffffff"
                                      foregroundColor="#ecebeb" />
                        </li>
                    ))}
                    </ul>

                    <ul className="list-group list-group-flush">
                        {
                        !selector?.loading && selector?.data?.map((value, index) => (
                        <li key={index} className="p-2 following-item-area overflow-hidden">
                            <div className="following-item d-flex justify-content-between align-items-center">
                                <a href={`/contact/${value.id}`}><div className="user-picture-container rounded-circle" style={{backgroundImage: `url(${value.avatar})`}} /></a>
                                <span className="name">
                                    <a href={`/contact/${value.id}`}>{value.name} </a>
                                    <p>{t('Suggested for you')}</p>
                                </span>
                                <span className="add-friend">
                                    <button className="btn btn-sm btn-dark add-contact-btn" onClick={()=>{ _sendFriendRequest(value.id) }}>
                                    {isLoading && div_id===value.id ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : t('Add')
                                        }
                                    </button>
                                </span>
                            </div>
                        </li>
                        ))
                        }
                    </ul>
                </div>
            </div>
            </>
    )
}