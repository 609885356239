import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import { useEffect } from "react";


function ImageView(props) {

   
    const [slider_index, setSliderIndex] = useState(-1);
    const [data, setCurrentPost] = useState([]);

    useEffect(() => {
        resetImageState();
        console.log(props.images)
    }, [props.images]);

  
    const resetImageState = () => {
        setSliderIndex(-1);
    };
   


    function nextSlider(n) {

        if (slider_index == -1) {
            var dd = props.imageIndex + n
            if (dd <= props?.images?.length) {
                setSliderIndex(dd)
            }
        } else {
            var dd = slider_index + n
            if (dd <= props?.images?.length) {
                setSliderIndex(dd)
            }

        }

    }



    return (

        <> {props.isModalVisible ?
            <Modal
                isOpen={props.isModalVisible}

                toggle={props.handleShowB}
                className={'modal-fullscreen'}
            >
                <ModalBody className="modal-body p-0">
                    <section className="postViewModal postViewModalfullscreen">
                        <div className="postViewModal-left">
                            <div className="postViewModalVideoWrapper">
                                <div className="postViewModalVideoWrapperContainer">
                                    <div className="postViewModalBasicPlayerWrapper">
                                       {
                                        props?.images?.length > 0 ?
                                            <>
                                            {slider_index > -1 ?
                                                <>
                                                {slider_index<props?.images?.length ?
                                                    <img src={props?.images[slider_index]} />
                                                    : 
                                                    <img src={props?.images[slider_index-1]} />
                                                }
                                                </>
                                                :
                                                <img src={props?.images[props.imageIndex]} />
                                            }     
                                            </>
                                        :
                                        ''}
                                    </div>
                                </div>
                            </div>
                            <button onClick={props.handleShowB} className="btn postViewModal-close-btn btn-close shadow-sm" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark" />
                            </button>
                            {props?.images?.length > 1 ?
                            <>
                            <button className="btn arrow-left-previous-video" type="button" onClick={() => nextSlider(-1)}>
                                <i className="fa-solid fa-angle-up" />
                            </button>
                            <button className="btn arrow-left-next-video" type="button" onClick={() => nextSlider(1)}>
                                <i className="fa-solid fa-angle-up" />
                            </button>
                            </>
                        : ''}

                        
                        </div>
                       
                    </section>
                </ModalBody>
            </Modal>
            : ''}
        </>
    )
}
export default React.memo(ImageView)