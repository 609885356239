import {Fragment} from "react";
import {Link,useLocation} from "react-router-dom";
import Notify from "../../CustomHooks/Notify";
import { ReactComponent as HomeIcon } from "../../assets/svg/home.svg";
import { ReactComponent as HomeIconBold } from "../../assets/svg/home-bold.svg";
import { ReactComponent as MeetingIcon } from "../../assets/svg/meeting.svg";
import { ReactComponent as MeetingIconBold } from "../../assets/svg/meeting-bold.svg";
import { ReactComponent as ChatIcon } from "../../assets/svg/chats.svg";
import { ReactComponent as ChatIconBold } from "../../assets/svg/chats-bold.svg";
import { ReactComponent as VideoIcon } from "../../assets/svg/video.svg";
import { ReactComponent as ProfileIcon } from "../../assets/svg/profile.svg";
import { ReactComponent as ContactsIcon } from "../../assets/svg/contact.svg";
import { useTranslation } from "react-i18next";

export default function LeftSide() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    return (
        <Fragment>
            <div className="app-left-sidebar sidebar">
                <div className="sidebar-scroll">
                    <div className="widget-area">
                        <div className="widget widget-key-features mb-4">
                    <div className="">
                        <div className="key-features">
                            <ul className="list-group list-group-flush">
                                <Link to={'/'}><li className="list-group-item widget-item-list "><HomeIcon className="me-2"/>{t('Home')}</li></Link>
                                <Link to={'/meeting'}><li className={`list-group-item widget-item-list ${location.pathname==='/meeting'?'active':''}`}><MeetingIcon className="me-2"/> {t('Meetings')}</li></Link>
                                <Link to={'/messages'}><li className={`list-group-item widget-item-list ${location.pathname==='/messages'?'active':''}`}><ChatIcon className="me-2"/> {t('Messages')}</li></Link>
                                <Link to={'/videos'}><li className={`list-group-item widget-item-list ${location.pathname==='/videos'?'active':''}`}><VideoIcon className="me-2"/> {t('Videos')}</li></Link>
                                <Link to={'/contacts'}><li className={`list-group-item widget-item-list ${location.pathname==='/contacts'?'active':''}`}><ContactsIcon className="me-2"/> {t('Contacts')}</li></Link>
                                <Link to={'/me'}><li className="list-group-item widget-item-list"><ProfileIcon className="me-2"/> {t('My Profile')}</li></Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="widget widget-key-features mb-4">
                    <div className="">
                        <div className="Account">
                            <ul className="list-group list-group-flush">
                                <Link to={'/settings'}><li className="list-group-item widget-item-list "><i className="fa-solid fa-cog me-2" /> {t('Settings')}</li></Link>
                                <Link to={'/terms'}><li className="list-group-item widget-item-list "><i className="fa-regular fa-circle-question me-2" /> {t('Terms')}</li></Link>
                                <Link to={'/policy'}><li className="list-group-item widget-item-list"><i className="fa-regular fa-id-card me-2" /> {t('Privacy')}</li></Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>

           <Notify/>                 

        </Fragment>
    )
}