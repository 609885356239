import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import API from "../Network/API";
import PostCreateModal from "./Modal/PostCreateModal";
import { useTranslation } from "react-i18next";

export default function CreatePost() {
    const {t} = useTranslation();
    const fileObj = [];
    const fileArray = [];
    const fileArrayAnother = [];
    const fileArrayVideo = [];
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [file, setFile] = useState([]);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const fileInput = React.useRef();

    const toggleModalSharePost = () => {
        setPostShareModalDialog(!post_modalDialog)
    };

    function onCreatePost() {
        if (selectedFile === null && message === null) {
            toast.error("Post is empty");
            return false;
        }
        const fd = new FormData();
        if (selectedFile != null) {
            let fileExtention = selectedFile.name.split('.').pop();
            if (fileExtention.toUpperCase() == "MP4" || fileExtention.toUpperCase() == "WMV" || fileExtention.toUpperCase() == "AVI" || fileExtention.toUpperCase() == "MKV" || fileExtention.toUpperCase() == "WEBM" || fileExtention.toUpperCase() == "MOV") {
                if (selectedFile) {
                    fd.append('video[]', selectedFile, selectedFile.name);
                }
            } else {
                if (file) {
                    for (var i = 0; i < file.length; i++) {
                        fd.append('image[]', file[i], file[i].name);
                    }
                }
            }
        }


        if (message != "") {
            fd.append('details', message);
        }
        setLoading(true)
        API.post("/posts", fd, {
            onUploadProgress: progressEvent => {
                // console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        })
            .then((response) => {
                setLoading(false)
                if (response.data.success === true) {
                    toast.success(response.data.message);
                    window.location = "/";
                } else if (response.data.success === false) {
                    //console.log(response.data.message);
                }
            })
            .catch((err) => {
                setLoading(false)
            });
    }

    const uploadHandler = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            let extn = e.target.files[i].name.split('.').pop();

            if (extn.toLowerCase() === 'mp4' || extn.toLowerCase() === 'wmv' || extn.toLowerCase() === 'avi' || extn.toUpperCase() === "MKV" || extn.toUpperCase() === "WEBM" || extn.toUpperCase() === "MOV") {
                fileArrayVideo.push(URL.createObjectURL(fileObj[0][i]));
            } else {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
            }
            fileArrayAnother.push(fileObj[0][i])
        }

        setFile(fileArrayAnother)

    }
    let msgText = t('Whats on your mind')+ ", " + localStorage.getItem("profile_name") + "?" ?? "";
    const onClick = () => {
        // fileInput.current.click();
        toggleModalSharePost();
    }
    return (
        <>
            <div className="news-feed-area">
                <div className="news-feed news-feed-form mb-3 bg-white shadow-sm p-4 mt-2">
                    <div className="news-feed-title d-flex align-items-center">
                        <div className="news-feed-title-icon">
                            <i className="fa-solid fa-pen" />
                        </div>
                        <h3 className="news-feed-title-heading ml-2">{t('Create New Post')}</h3>
                    </div>
                    <div className="">
                        <button type="button" className="text-start btn btn-light w-100 rounded-pill text-secondary create-new-post-bg" onClick={onClick}>{msgText}</button>
                    </div>
                    <form >
                        <div className="form-group d-none">
                            <textarea onClick={onClick} style={{ height: '47px' }} name="message" className="form-control"
                                placeholder={msgText} spellCheck="false"
                                defaultValue={""} onChange={(e) => setMessage(e.target.value)} />

                            <input type="file" multiple={true} accept="audio/*,video/*,image/*"
                                style={{ display: 'none' }}
                                onChange={uploadHandler} ref={fileInput} />
                        </div>
                        <ul className="button-group d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <Link to={"/meeting"}>
                                    <li className="photo-btn">
                                        <button type="button"><i className="fa-solid fa-video" />{t('Live Meeting')}</button>
                                    </li>
                                </Link>
                                <li className="video-btn px-3">
                                    <button type="button" onClick={onClick}>
                                        <i className="fa-solid fa-photo-film" /> {t('Photos/Video')}
                                    </button>
                                </li>
                            </div>
                            <li className="post-btn">
                                {(selectedFile == null && message == "") ?
                                    <button type="button" className="ms-auto btn bg-primary text-white"
                                        aria-expanded="false" disabled>{t('Post')}</button>
                                    :
                                    <button onSubmit={onCreatePost} type={'button'} className="ms-auto btn bg-primary text-white"
                                        aria-expanded="false" onClick={onCreatePost}>{t('Post')} {isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <span></span>
                                        )}</button>

                                }

                            </li>
                        </ul>
                    </form>

                </div>
            </div>
            <PostCreateModal isModalVisible={post_modalDialog} handleShowB={() => setPostShareModalDialog(false)} />
        </>
    )
}