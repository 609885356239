import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../Network/API";
import { ADD_MESSAGE_SUCCESS } from "../../Reducer/userInboxReducer";
import ImageView from "../Modal/ImageView";

const UserChatFile = ({ user, recentChatFile, self_data }) => {

    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [img_index, setImIndex] = useState(null);
    const [imageView_modalDialog, setImageViewModalDialog] = useState(false);
    const [image_array, setImageArray] = useState([]);
    const member_data = useSelector((state) => state.userSelf.data)
    const deleteMessageAll = (messageId) => {
        setLoading(true);
        var shouldDelete = window.confirm('Do you want to remove conversation?');
        if (!shouldDelete) {
            setLoading(false)
            return false
        }
        API.get(`conversations/${messageId}/delete`).then(response => {
            setLoading(false);
            toast.success('All conversations delete success');
            window.location.href = '/messages'
        }).catch((error) => {
            setLoading(false);
        });
    }

    const toggleImageView = (value, imgindex) => {
        const img = value.map(v => v.file_name);
        setImageArray(img);
        setImIndex(imgindex);
        setImageViewModalDialog(!imageView_modalDialog);
    }


    return (
        <>
            <div className="chater-info bg-white p-3 margin-top-minex">
                <figure className="d-flex justify-content-center align-items-center">
                    <div
                        className="user-picture-container rounded-circle height-max-120"
                        style={{ background: `url(${user.avatar})` }}
                    />
                </figure>
                <h6 className="text-center mb-0"><Link to={'/contact/' + user.id} target="_blank">{user.name}</Link></h6>
                <span className="text-center d-block">{user.is_online == 1 ? 'Online' : user.last_seen1}</span>
                <div className="userabout">
                    <div
                        className="accordion accordion-flush chater-tog"
                        id="accordionFlushExample"
                    >
                        <div className="accordion-item">
                            <div className="accordion-header" id="flush-headingOne">
                                <div
                                    className="accordion-button collapsed pe-0 ps-0 "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                >
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <h6 className="">Photos and Documents</h6>
                                        <span className="me-2 text-end chater-file-count">{recentChatFile.length}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="flush-collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div className="accordion-body pe-0 ps-0">
                                    <div className="row g-2">
                                        {recentChatFile?.map((value, index) => (
                                            <>
                                                {value.message_type == 1 ?
                                                    <div className="col-4" key={index}>
                                                        <div onClick={() => toggleImageView(recentChatFile, index)}
                                                            className="chater-msg-img-conteiner rounded-3 clickable"
                                                            style={{ background: `url(${value.file_name})` }}
                                                        />
                                                    </div>
                                                    : ''}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul>

                        <li>
                            {" "}
                            <a href="javascript:void(0)" title="" onClick={() => deleteMessageAll(user.id)}>
                                <span>
                                    <i className="fa-solid fa-trash" />
                                </span>
                                Delete Conversation
                            </a>
                        </li>
                        <li>
                            {" "}
                            <a href="javascript:void(0)" title="">
                                <span>
                                    <i className="fa-solid fa-ban" />
                                </span>{" "}
                                Block {user.name}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <ImageView images={image_array} isModalVisible={imageView_modalDialog} handleShowB={() => setImageViewModalDialog(false)} imageIndex={img_index} />

        </>

    )
}
export default UserChatFile;