import Pusher from 'pusher-js';
import Echo from "laravel-echo";
export default function createSocketConnection() {
    if (!window.Echo) {
        const token = localStorage.getItem("connect_token");
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'key56556563562563624376',
            wsHost: process.env.REACT_APP_SOCKET_URL,
            wssHost: process.env.REACT_APP_SOCKET_URL,
            wsPort: 6001,
            wssPort: 443,
            forceTLS: true,
            encrypted: true,
            disableStats: true,
            authEndpoint: process.env.REACT_APP_SOCKET_AUTH_URL,
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });
    }
}