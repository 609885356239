import React, { Component, Fragment, useEffect, useState, memo } from "react";
import API from "../../Network/API";
import { Link } from "react-router-dom";
import NotificationProps from "../../Props/NotificationProps";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Agent from "../../Network/Agent";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NotificationIcon } from "../../assets/svg/notification.svg";
import { useTranslation } from "react-i18next";

toast.configure();

const Notification = () => {
    const { t } = useTranslation()
    const [NotificationList, setNotificationList] = useState([]);
    const [data_read, setData_read] = useState(false);
    const [total_count, setTotal_count] = useState(0);
    const dispatch = useDispatch();

    const handleNotificationView = () => {
        Agent.Notification.readAll();
        // Agent.Notification.latest(dispatch);
    }


    const notificationData = useSelector((state) => state.notifyReducer?.data)

    return (
        <div>
            <div className="option-item">
                <div className="dropdown notifications-nav-item">
                    <Link to={''} onClick={() => handleNotificationView()} className="dropdown-bs-toggle" role="button" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <div className="notifications-count">
                            <NotificationIcon />
                            {notificationData?.total_count > 0 ? <span>{notificationData?.total_count}</span> : ""}
                        </div>
                    </Link>
                    <div className="dropdown-menu">
                        <div className="notifications-header d-flex justify-content-between align-items-center">
                            <h3>{t('Notifications')} - <button type={'button'} onClick={handleNotificationView}
                                className="ms-auto btn btn-primary btn-sm text-white"> {t('Read All')}</button></h3>
                            <i className="fa-solid fa-ellipsis" />
                        </div>
                        <div className="notifications-body">
                            {notificationData.notification?.map((value, index) => (

                                <div key={value.id}
                                    className={`item d-flex justify-content-between align-items-center single-notifications  ${value.read_at == null ? "notification-unseen-color" : ""}`}>
                                    <div className="figure">
                                        <a href={
                                            value.type == 4 ? `/contact/${value.app_url}` :
                                                value.type == 0 ? `/meeting-details/${value.app_url}` :
                                                    value.type == 6 ? `/meeting-details/${value.app_url}` :
                                                        value.type == 7 ? `/stories/${value.app_url}` :
                                                            `/post/${value.app_url}`
                                        }>
                                            <div className="user-picture-container rounded-circle " style={{ background: `url("${value.avatar}")` }} />
                                        </a>
                                    </div>
                                    <div className="text">
                                        <a href={
                                            value.type == 4 ? `/contact/${value.app_url}` :
                                                value.type == 0 ? `/meeting-details/${value.app_url}` :
                                                    value.type == 6 ? `/meeting-details/${value.app_url}` :
                                                        value.type == 7 ? `/stories/${value.app_url}` :
                                                            `/post/${value.app_url}`
                                        }
                                        >
                                            <h4>{value.title}</h4>
                                            {value.details ? <span>{value.details}</span> : ''}
                                            <span className="main-color d-block">{value.times}</span>
                                        </a>
                                    </div>
                                </div>

                            ))}
                            <div className="view-all-requests-btn text-center ">
                                <Link to={`/notifications`} className="btn default-btn w-100">{t('View All Notifications')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(Notification);