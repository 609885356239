import React from 'react'
import {Link} from "react-router-dom";
import API from "../Network/API";
import {toast} from "react-toastify";
const _sendFriendRequest =(id)=>{
    API.post("/friend_request",{
        user_id: id,
    }).then((response) => {
        if (response.data.success === true) {
            toast.success("Request sent");
        }
    }).catch((error) => {
        if( error.response ){
            toast.success("something was wrong!");
        }
    });
}
const UserList = ({user}) => (

    <li className="list-group-item " key={Math.random()}>
        <div className="d-flex justify-content-between align-items-center">
            <div className="reaction-pp d-flex align-items-center">
                <div className="recent-chat-user-img me-2">
                    <Link to={`/contact/${user.user_id}`}>
                        <div className="user-picture-container rounded-circle" style={{background: `url('${user.avatar}')`}} />
                    </Link>
                </div>
                <div className="name">
                    <Link to={`/contact/${user.user_id}`} className="text-dark">{user.name}</Link>
                    <p>{user.created_at}</p>
                </div>
            </div>
            <div className="reaction-btn">
                {user.is_friend?
                <Link to={`/messages/thread/${user.user_id}`} type="button" className="btn btn-primary btn-width ">Massage</Link>
                    :
                    <a onClick={()=>_sendFriendRequest(user.user_id)} type="button" className="btn btn-outline-primary btn-width clickable "><i
                        className="fa-solid fa-plus me-2"/>Add Contact</a>
                }
            </div>
        </div>
    </li>
)
export default React.memo(UserList)