import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import API from "../../Network/API";
import { useEffect } from "react";
import { DELETE_COMMENT, EDIT_POST_FETCH_SUCCESS, POST_FETCH_COMMENT_ADDON } from "../../Reducer/postReducer";
import { DELETE_FPOST_COMMENT, FPOST_FETCH_COMMENT_ADDON } from "../../Reducer/friendProfileReducer";
import { SINGLE_DELETE_COMMENT, SINGLE_POST_FETCH_COMMENT_ADDON } from "../../Reducer/singlePostReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function PostViewFull(props) {

    const {t} = useTranslation()
    const [love_react, setLoveReact] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [comment_love_react, setCommentLoveReact] = useState(false);
    const [reply_comment_delete, setReplyCommentDelete] = useState(false);
    const [reply_comment_id, setReplyCommentID] = useState(false);
    const [comment_love_react_add, setCommentLoveReactAdd] = useState(0);

    const [write_comment, setWriteComment] = useState('');
    const [reply_comment, setReplyComment] = useState('');

    const [p_id_like, setPIDLike] = useState(0);
    const [p_id_com, setPIDCom] = useState(0);
    const [set_like, setLike] = useState(0);
    const [last_comments, setLastComments] = useState([]);
    const [set_comment, setComment] = useState(0);

    const [postId, setPostId] = useState(0);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [show, setShow] = useState(false);
    const [show_comment_box, setShowCommentBox] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [is_comment_loading, setCommentLoading] = useState(false);
    const [reply_com_id, setReplyComID] = useState(0);
    const [comment_id, setCommentID] = useState(0);
    const [is_com_delete, setISComDelete] = useState(false);
    const [comment_page, setCommentPage] = useState(2);
    const [next_page_url, setNextPageURL] = useState(null);
    const [slider_index, setSliderIndex] = useState(-1);
    const selector_post = useSelector((state) => state.postReducer)
    const post_data = selector_post?.post_data;
    const [data, setCurrentPost] = useState(null);
    const toggleModalSharePost = () => {
        setPostShareModalDialog(!post_modalDialog)
    };
    const dispatch = useDispatch();

    useEffect(() => {
         
         const post = post_data.find((post) => post.id === props?.postId);
        // console.log(post);
        setCurrentPost(post);
        setLastComments(post?.last_comments)
        if (post?.last_comments?.length > 10) {
            setNextPageURL('/post_comment_history?page=2');
        }
        resetImageState();
    }, [props?.postId,post_data]);

  
    const resetImageState = () => {
        setSliderIndex(-1);
    };
    
    function fetchPostCommentNext(postId) {

        setCommentLoading(true)

        API.get('post_comment_history/?page=' + comment_page + '&post=' + postId, {
            'offset': last_comments.length
        }).then((response) => {
            setCommentLoading(false)
            const paginator = response.data.data;
            if (paginator.get_posts.length) {
                setLastComments([...last_comments, ...paginator.get_posts])
                setNextPageURL(paginator.next_page_url)
                setCommentPage((comment_page + 1))
            }
        }).catch((error) => {
            setCommentLoading(false)
        });

    }


    const postLike = (post_id) => {
        setLoading(true)
        setLoveReact(true)
        setLike(0)
        setPIDLike(post_id)
        API.post("/v3/post_likes", {
            post_id: post_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            setLoveReact(true)
            if (response.data.success === true) {
                dispatch(EDIT_POST_FETCH_SUCCESS(response.data.data));
                // toast.success('Post loved Successfully');
            } else {
                setLoveReact(false)
            }
        });
    }

    const toggleReply = (com_id) => {
        setShow(!show)
        setPIDCom(com_id)
    };

    const toggleComment = (post_id) => {
        setShowCommentBox(!show_comment_box)
        setPostId(post_id)
    };


    const commentLike = (post_id, comment_id) => {
        setLoading(true)
        setCommentLoveReact(true)
        setPIDCom(comment_id)
        setCommentLoveReactAdd(1)
        API.post("/comment_like", {
            post_id: post_id,
            comment_id: comment_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                // toast.success('Comment loved Successfully');
            }
        });
    }

    const commentsReplyDelete = (comments_id) => {
        setLoading(true)
        API.delete("/reply_comment_delete/" + comments_id).then((response) => {
            if (response.data.success === true) {
                setLoading(true)
                setReplyCommentDelete(true)
                setReplyCommentID(comments_id)
                toast.success('Comment deleted');
            }
        });
    }

    const handleReplyMessage = (p_id, cm_id) => {
        setLoading(true)
        setReplyComID(cm_id)
        API.post("/comment_reply", {
            post_id: p_id,
            comment_id: cm_id,
            comment: reply_comment,
        }).then((response) => {
            setLoading(false)
            setReplyComment('')
            if (response.data.success === true) {

                // toast.success('Reply sent');
            }
        });
    }

    const isMessageEmpty = write_comment.trim() === '';
    const isReplyCommentEmpty = reply_comment.trim() === '';

    const submit_comment = (p_id) => {
        if (write_comment === null) {
            toast.error('Enter something!');
            return false;
        }
        setLoading(true)
        setComment(1)
        API.post("/post_comments", {
            post_id: p_id,
            comment: write_comment,
        }).then((response) => {
            setLoading(false)
            setWriteComment("")
            if (response.data.success === true) {
                dispatch(POST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(FPOST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(SINGLE_POST_FETCH_COMMENT_ADDON(response.data.data))
                setPostId(p_id)
                // console.log(last_comments?.length)
                // toast.success('Comment sent successfully');
            }
        });

    }


    const commentsDelete = (comments_id, post_id) => {
        setLoading(true)
        API.delete("/comment_delete/" + comments_id).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                setISComDelete(true)
                setCommentID(comments_id)
                setPostId(post_id)
                setComment(-1)
                const comPayLod = {
                    comment_id: comments_id,
                    post_id: post_id,
                }
                
                dispatch(DELETE_COMMENT(comPayLod))
                // dispatch(DELETE_FPOST_COMMENT(comPayLod))
                // dispatch(SINGLE_DELETE_COMMENT(comPayLod))
                toast.success('Comment deleted');
            }
        });
    }

    const share_post = (p_id) => {
        setPostId(p_id);
        toggleModalSharePost()
    }


    function nextSlider(n) {

        if (slider_index == -1) {
            var dd = props.imageIndex + n
            if (dd <= data.images?.length) {
                setSliderIndex(dd)
            }
        } else {
            var dd = slider_index + n
            if (dd <= data.images?.length) {
                setSliderIndex(dd)
            }

        }

    }



    return (

        <> {props.isModalVisible ?
            <Modal
                isOpen={props.isModalVisible}

                toggle={props.handleShowB}
                className={'modal-fullscreen'}
            >
                <ModalBody className="modal-body p-0">
                    <section className="postViewModal postViewModalfullscreen">
                        <div className="postViewModal-left">
                            <div className="postViewModalVideoWrapper">
                                <div className="postViewModalVideoWrapperContainer">
                                    <div className="postViewModalBasicPlayerWrapper">
                                       {
                                        data?.images?.length > 0 ?
                                            <>
                                            {slider_index > -1 ?
                                                <>
                                                {slider_index<data?.images?.length ?
                                                    <img src={data?.images[slider_index]?.image} />
                                                    : 
                                                    <img src={data?.images[slider_index-1]?.image} />
                                                }
                                                </>
                                                :
                                                <img src={data?.images[props.imageIndex]?.image} />
                                            }     
                                            </>
                                        :
                                        ''}
                                    </div>
                                </div>
                            </div>
                            <button onClick={props.handleShowB} className="btn postViewModal-close-btn btn-close shadow-sm" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark" />
                            </button>
                            {data?.images?.length > 1 ?
                            <>
                            <button className="btn arrow-left-previous-video" type="button" onClick={() => nextSlider(-1)}>
                                <i className="fa-solid fa-angle-up" />
                            </button>
                            <button className="btn arrow-left-next-video" type="button" onClick={() => nextSlider(1)}>
                                <i className="fa-solid fa-angle-up" />
                            </button>
                            </>
                        : ''}

                        {data?.videos?.length > 1 ?
                           <>
                            <div className="reels-sound-area postViewModal-sound-push">
                                <div className="postViewModal-sound-push-area">
                                    <i className="fa-solid fa-play" />
                                </div>
                            </div>
                            <div className="reels-sound-area postViewModal-sound-volume">
                                <button className="btn postViewModal-sound-volume-area postViewModal-sound-push-area">
                                    <i className="fa-solid fa-volume" />
                                </button>
                            </div>
                           </>
                             : ''}
                        </div>
                        <div className="postViewModal-right">
                            <div className="postViewModalCreatorArea">
                                <div className="postViewModalCommentListContainer postViewModalCommentListContainer-pad-24">
                                    <div className="postViewModalProfileWrapper">
                                        <div className="postViewModalDescriptionContentWrapper">
                                            <div className="postViewModalUserInfoContainer">
                                                <div className="news-feed-area">
                                                    <div className="news-feed news-feed-post m-0 p-0">
                                                        <div className="post-header d-flex justify-content-between align-items-center">
                                                            <div className="d-flex">
                                                                <div className="image">
                                                                    <Link to={`/contact/${data?.user_id}`}>
                                                                        <div className="user-picture-container rounded-circle"
                                                                            style={{ backgroundImage: `url(${data?.avatar})`, objectFit: 'fill' }} /></Link>
                                                                </div>
                                                                <div className="info ms-3">
                                                                    <span className="name">
                                                                        <Link to={`/contact/${data?.user_id}`}>{data?.post_by}
                                                                            {data?.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                                            {data?.is_shared_post ?
                                                                                <span className=""><small className="fw-100 text-grey"> shared </small> {data?.root_user_name} <Link to={`/post/${data?.share_post_id}`}><span className="fw-700 text-grey-900">Post</span></Link></span> : ""}
                                                                        </Link>
                                                                    </span>
                                                                    <span className="small-text"><a href="#">{data?.post_time}</a></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="reelsBody">
                                                            <p dir="auto" className="post-text text-dark">
                                                                {data?.is_shared_post ? data?.self_details : showMore ? data?.details : `${data?.details?.substring(0, 450)}`}
                                                                <>
                                                                    {data?.details?.length < 450 ? '' :
                                                                        <a className="clickable" style={{ fontSize: '12px', color: '#0d6efd', paddingLeft: '5px' }} onClick={() => setShowMore(!showMore)}>{showMore ? "" : "..." + t('See More')}</a>
                                                                    }
                                                                </>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="postViewModalButtonWrapper">
                                            <div className="postViewModalButtonWrapper-padding">
                                                {data?.is_like ?
                                                    <button type="button" className="postViewModalButtonWrapper-btn ms-3">
                                                        <span className="postViewModalButtonWrapper-btn-icon">
                                                            <i className="fa-solid fa-heart i-loved" />
                                                        </span>
                                                        <strong className="postViewModalButtonWrapper-btn-StrongText">{data?.totalLikes + set_like}</strong>
                                                    </button>
                                                    :
                                                    <button className={'clickable postViewModalButtonWrapper-btn ms-3'} onClick={() => postLike(data?.id)} type="button">
                                                        <span className="postViewModalButtonWrapper-btn-icon">
                                                            <i className={`fa-solid fa-heart ${love_react && p_id_like === data?.id ? 'i-loved' : ''}`} />
                                                        </span>
                                                        <strong className="postViewModalButtonWrapper-btn-StrongText">{data?.totalLikes + set_like}</strong>
                                                    </button>
                                                }

                                                <button type="button" className="postViewModalButtonWrapper-btn ms-3">
                                                    <span className="postViewModalButtonWrapper-btn-icon">
                                                        <i className="fa-solid fa-comment" />
                                                    </span>
                                                    <strong className="postViewModalButtonWrapper-btn-StrongText">{data?.totalComments + set_comment}</strong>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="postViewModalCommentsArea">
                                            <div className="postViewModalCommentsAreaHeading Promoted_Post_content">
                                                <h6>{t('Comments')}</h6>
                                            </div>
                                            <div className="postViewModalCommentsAreaWrapp mt-3">
                                                <div className="news-feed-area">
                                                    {last_comments?.length > 0 ?
                                                        <div className="news-feed news-feed-post m-0 p-0">
                                                            {last_comments?.slice(0, 10)?.map((getcomment, i) => (
                                                                <div key={i} className="post-body">
                                                                    <div className="post-comment-list">
                                                                        <div className="comment-list d-flex justify-content-between">
                                                                            <div className="comment-image">
                                                                            <Link to={`/contact/${getcomment.comment_user_id}`}>
                                                                                <div className="user-picture-container rounded-circle"
                                                                                    style={{ backgroundImage: `url(${getcomment.avatar})` }} />
                                                                            </Link>
                                                                            </div>
                                                                            <div className="comment-list-meta">
                                                                                <div className="comment-info shadow-sm">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="name">
                                                                                        <Link to={`/contact/${getcomment.comment_user_id}`}>{getcomment.comment_by}</Link>
                                                                                        {getcomment.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                                                        </div>
                                                                                        <a className="time" href="#">{getcomment.comment_time}</a>
                                                                                    </div>
                                                                                    <p>{getcomment.comment}.
                                                                                    </p>
                                                                                </div>
                                                                                <ul className="comment-react">
                                                                                    <li className="post-react">
                                                                                    {getcomment.is_like ?
                                                                                        <a className={'clickable'}><i
                                                                                            className="fa-solid fa-heart" /><span>{t('Love')}</span>
                                                                                            <span
                                                                                                className="number"> {getcomment.total_likes} </span></a>
                                                                                        :
                                                                                        <a className={'clickable'}
                                                                                            onClick={() => commentLike(data.id, getcomment.id)}><i
                                                                                                className={`fa-solid fa-heart ${p_id_com === getcomment.id && comment_love_react ? 'i-loved' : 'text-dark'} `} /><span>{t('Love')}</span>
                                                                                            <span
                                                                                                className="number"> {p_id_com === getcomment.id && comment_love_react ? getcomment.total_likes + comment_love_react_add : getcomment.total_likes > 0 ? getcomment.total_likes : ''} </span></a>
                                                                                    }
                                                                                    </li>
                                                                                    <li className="post-comment">
                                                                                    <a className={'clickable'}
                                                                                        onClick={() => toggleReply(getcomment.id)}><i
                                                                                            className="fa-solid fa-comment" />{t('Reply')}{getcomment.comment_reply?.length > 0 ? `(${getcomment.comment_reply?.length})` : ''} <span
                                                                                                className="number"> </span></a>
                                                                                    </li>
                                                                                    <li className="post-comment-replay-icon">
                                                                                        <a href="#"><i className="fa-solid fa-angle-up" /></a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            {getcomment.is_deletable === 1 ?
                                                                            <div className="comment-button">
                                                                                <div className="dropdown">
                                                                                    <button className="dropdown-toggle" type="button"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-haspopup="true" aria-expanded="false"><i
                                                                                            className="fa-solid fa-ellipsis-vertical" /></button>
                                                                                    <ul className="dropdown-menu">

                                                                                        <li><a className="dropdown-item d-flex align-items-center clickable"
                                                                                            onClick={() => commentsDelete(getcomment.id, data.id)}><i
                                                                                                className="fa-solid fa-trash-can" /> {t('Delete')} </a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                        </div>
                                                                    </div>

                                                                    {getcomment.comment_reply?.length > 0 ?
                                                                    <>
                                                                        {getcomment.comment_reply?.map((reply, i) => (
                                                                            <>
                                                                                <div key={Math.random()} id={`reply${reply.id}`}
                                                                                    className={`post-comment-list post-comment-replay ${p_id_com === getcomment.id && show ? '' : 'd-none'} ${reply_comment_delete && reply_comment_id === reply.id ? 'd-none' : ''}`}>
                                                                                    <div className="comment-list d-flex justify-content-between">
                                                                                        <div className="comment-image">
                                                                                            <Link to={`/contact/${reply.comment_user_id}`}>
                                                                                                <div
                                                                                                    className="user-picture-container rounded-circle"
                                                                                                    style={{ backgroundImage: `url(${reply.avatar})` }} />
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div className="comment-list-meta">
                                                                                            <div className="comment-info shadow-sm">
                                                                                                <div className="d-flex justify-content-between">
                                                                                                    <div className="name">
                                                                                                        <Link
                                                                                                            to={`/contact/${reply.comment_user_id}`}>{reply.comment_by}</Link>
                                                                                                        {reply.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                                                                    </div>
                                                                                                    <span className="time"
                                                                                                    >{reply.comment_time}</span>
                                                                                                </div>
                                                                                                <p>{reply.comment}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        {reply.is_deletable ?
                                                                                            <div className="comment-button">
                                                                                                <div className="dropdown">
                                                                                                    <button className="dropdown-toggle"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="dropdown"
                                                                                                        aria-haspopup="true"
                                                                                                        aria-expanded="false">
                                                                                                        <i
                                                                                                            className="fa-solid fa-ellipsis-vertical" />
                                                                                                    </button>
                                                                                                    <ul className="dropdown-menu">

                                                                                                        <li><span
                                                                                                            onClick={() => commentsReplyDelete(reply.id)}
                                                                                                            className="dropdown-item d-flex align-items-center clickable"
                                                                                                        ><i
                                                                                                                className="fa-solid fa-trash-can" /> {t('Delete')}</span>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div> : ""
                                                                                        }
                                                                                    </div>
                                                                                </div>


                                                                            </>

                                                                        ))}


                                                                    </> : ""
                                                                }
                                                                <div
                                                                    className={`post-comment-list post-comment-replay  ${p_id_com === getcomment.id && show ? '' : 'd-none'}`}>
                                                                    <form className="post-footer d-flex justify-content-between">
                                                                        <div className="footer-image">
                                                                            <Link to="javascript:void(0)">
                                                                                <div
                                                                                    className="user-picture-container rounded-circle"
                                                                                    style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                                                                            </Link>
                                                                        </div>
                                                                        <div className="form-group post-footer-comment-form">
                                                                            <textarea name="message" className="form-control"
                                                                                placeholder={t('Post your reply')}
                                                                                defaultValue={'@' + getcomment.comment_by + ' '} value={reply_comment} onChange={(e) => setReplyComment(e.target.value)} />
                                                                            <label className="post-footer-comment-icon">
                                                                                {/* <a href="javascript:void(0)"><i
                                                                                    className="fa-regular fa-face-smile" /></a> */}
                                                                                <button type="button" onClick={() => handleReplyMessage(data.id, getcomment.id)} className={isReplyCommentEmpty ? 'post-footer-comment-submit bg-secondary' : 'post-footer-comment-submit'} disabled={isReplyCommentEmpty}>{t('Post')}  {isLoading && reply_com_id === getcomment.id ? (
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                ) : (
                                                                                    <span></span>
                                                                                )}
                                                                                </button>
                                                                            </label>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                </div>
                                                            ))}

                                                            {data?.last_comments?.length > 10 && next_page_url != null ?
                                                                <div className="view-comment-text text-center">
                                                                    <a className={'clickable'} onClick={() => fetchPostCommentNext(data?.id)}>{t('View more')}{is_comment_loading ? (
                                                                        <span
                                                                            className="spinner-border spinner-border-sm ml-5"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    ) : (
                                                                        <span></span>
                                                                    )}</a>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                        : 'Be the first to comment on this'}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="postViewModalBottomCommentContainer">
                                <div className="postViewModalCommentBar">
                                    <div className="postViewModal-replay position-relative">
                                        <div className={`post-footer d-flex justify-content-between `}>
                                            <div className="footer-image">
                                                <a href="javascript:void(0)">
                                                    <div className="user-picture-container rounded-circle"
                                                        style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                                                </a>
                                            </div>
                                            <div className="form-group post-footer-comment-form">
                                                <textarea name="message" className="form-control" placeholder={t('Post your comments')}
                                                    value={write_comment} onChange={(e) => setWriteComment(e.target.value)}
                                                />
                                                <label className="post-footer-comment-icon">
                                                    <button type="button" onClick={() => submit_comment(data.id)} className={isMessageEmpty ? 'post-footer-comment-submit bg-secondary' : 'post-footer-comment-submit'} disabled={isMessageEmpty}>
                                                        {t('Post')}
                                                    </button>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ModalBody>
            </Modal>
            : ''}
        </>
    )
}
export default React.memo(PostViewFull)