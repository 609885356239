import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../Network/API";
import { MEMBER_FETCH_REMOVE } from "../../Reducer/groupMemberReducer";
import { RECEIVE_SOCKET_GROUP_MESSAGE } from "../../Reducer/userInboxReducer";
import AddGroupMember from "../Modal/AddGroupMember";
import ImageView from "../Modal/ImageView";

const GroupChatFile = ({ user, recentChatFile, members }) => {
    const [isLoading, setLoading] = useState(false);
    const [isMemberDeleteLoading, setMemberDeleteLoading] = useState(false);
    const [img_index, setImIndex] = useState(null);
    const [imageView_modalDialog, setImageViewModalDialog] = useState(false);
    const [image_array, setImageArray] = useState([]);
    const [member_id, setMemberID] = useState(0);
    const [modalDialog, setModalDialog] = useState(false);
    const dispatch = useDispatch();
    const member_data = useSelector((state) => state.members)
    const member_role = useSelector((state) => state.role)

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    };

    const deleteMessageAll = (messageId) => {
        setLoading(true);
        var shouldDelete = window.confirm('Do you want to remove conversation?');
        if (!shouldDelete) {
            setLoading(false)
            return false
        }
        API.get(`conversations/${messageId}/delete`).then(response => {
            setLoading(false);

            toast.success('Message deleted');
        }).catch((error) => {
            setLoading(false);
        });
    }

    const deleteMember = (group_id, user_id) => {
        setMemberID(user_id)
        setMemberDeleteLoading(true);
        var shouldDelete = window.confirm('Do you really want to remove this member?');
        if (!shouldDelete) {
            setMemberDeleteLoading(false)
            return false
        }
        API.delete(`chat_groups/member/${user_id}/${group_id}`).then(response => {
            setMemberDeleteLoading(false);
            dispatch(MEMBER_FETCH_REMOVE(user_id))
            toast.success('Member removed');
        }).catch((error) => {
            setMemberDeleteLoading(false);
        });
    }


    const leaveGroup = (group_id) => {
        setLoading(true);
        var shouldDelete = window.confirm('Do you really want to remove this group?');
        if (!shouldDelete) {
            setLoading(false)
            return false
        }
        API.delete(`chat_groups/${group_id}/leave`).then(response => {
            setLoading(false);
            // console.log(response.data)
            toast.success('Leaved');
        }).catch((error) => {
            setLoading(false);
        });
    }

    const addGroupMember = (group_id, user_id) => {
        setMemberDeleteLoading(true);
        var shouldDelete = window.confirm('Do you really want to remove this group?');
        if (!shouldDelete) {
            setMemberDeleteLoading(false)
            return false
        }
        API.put(`chat_groups/${group_id}/members/${user_id}/make-admin`, { validateStatus: () => true }).then(response => {
            setMemberDeleteLoading(false);
            if (response.status == 404) {
                toast.error('You can not add admin');
            }
            if (response.status == 200) {
                dispatch(RECEIVE_SOCKET_GROUP_MESSAGE(response.data.data.conversation))
                toast.success('Group admin added');
            }
            // console.log(response.data)


        }).catch((error) => {
            toast.error('You can not add admin');
            // console.log(JSON.stringify(error))
            setMemberDeleteLoading(false);
        });
    }

    const toggleImageView = (value, imgindex) => {
        const img = value.map(v => v.file_name);
        setImageArray(img);
        setImIndex(imgindex);
        setImageViewModalDialog(!imageView_modalDialog);
    }


    return (<div className="chater-info bg-white p-3 margin-top-minex">
        <figure className="d-flex justify-content-center align-items-center">
            <div
                className="user-picture-container rounded-circle height-max-120"
                style={{ background: `url(${user.avatar})` }}
            />
        </figure>
        <h6 className="text-center mb-0">{user.name}</h6>
        <span className="text-center d-block">Group ({member_data?.data.length} Members)</span>
        <div className="userabout">
            <div
                className="accordion accordion-flush chater-tog"
                id="accordionFlushExample"
            >
                <div className="accordion-item">
                    <div className="accordion-header" id="flush-headingOne">
                        <div
                            className="accordion-button collapsed pe-0 ps-0 "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                        >
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <h6 className="">Photos and Documents</h6>
                                <span className="me-2 text-end chater-file-count">{recentChatFile.length}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                    >
                        <div className="accordion-body pe-0 ps-0">
                            <div className="row g-2">
                                {recentChatFile?.map((value, index) => (
                                    <>
                                        {value.message_type == 1 ?
                                            <div className="col-4" key={index}>
                                            <div onClick={() => toggleImageView(recentChatFile, index)}
                                                className="chater-msg-img-conteiner rounded-3 clickable"
                                                style={{ background: `url(${value.file_name})` }}
                                            />
                                        </div>
                                            : ''}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                        <div className="accordion-button pe-0 ps-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <h6 className="m-0 p-0">Member</h6>
                        </div>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample" style={{}}>
                        <div className="accordion-body  pe-0 ps-0">
                            <div className="group-connects-adds">
                                <div className="group-connects-adds-content">
                                    <div className={`d-flex justify-content-start align-items-center ${member_role?.role_id == 2 ? '' : 'd-none'}`}>
                                        <a href="javascript:void(0)">
                                            <div className="user-text bg-primary rounded-circle group-connects-adds-btn">
                                                <i className="fas fa-user-plus" />
                                            </div>
                                        </a>
                                        <span className="name ms-2 "><a href="javascript:void(0)" onClick={() => toggleModal()} className="text-dark">Add Member</a></span>
                                    </div>
                                    {member_data?.data.map((member, index) => (
                                        <div className=" d-flex justify-content-between align-items-center">
                                            <div className="d-flex">
                                                <div className="image">
                                                    <a href="javascript:void(0)"><div className="user-picture-container rounded-circle" style={{ background: `url(${member.avatar})` }} /></a>
                                                </div>
                                                <div className="info ms-2">
                                                    <span className="name d-block fw-bold ">
                                                        <a href="javascript:void(0)" className="text-dark">{member.name}</a>
                                                    </span>
                                                    <span className="small-text"><a href="javascript:void(0)">{member.designation}</a></span>
                                                </div>
                                            </div>
                                            <div className="Group-Admin d-flex align-items-center">
                                                {member.role == 2 ?
                                                    <div className="Group-Admin-text text-warning">
                                                        <i className="fas fa-star" />
                                                        <span className="ms-1">Group Admin</span>
                                                    </div>
                                                    : ''}
                                                <div className="global-dropdown">


                                                    {isMemberDeleteLoading && member_id == member.id ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm ml-5"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                        <>
                                                            {member_role?.role_id == 2 ?
                                                                <div className="dropdown">
                                                                    <button className="dropdown-toggle dropdown-bg-transparent" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => deleteMember(user.id, member.id)}><i className="fa-solid fa-arrow-left" />
                                                                            Remove Member</a></li>
                                                                        <li><a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => addGroupMember(user.id, member.id)}><i className="fa-solid fa-plus-circle" />
                                                                            Make Admin</a></li>

                                                                    </ul>
                                                                </div>
                                                                : ''}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul>

                <li className={`${member_role?.role_id == 2 ? '' : 'd-none'}`}>
                    {" "}
                    <a href="javascript:void(0)" title="" onClick={() => deleteMessageAll(user.id)}>
                        <span>
                            <i className="fa-solid fa-trash" />
                        </span>
                        Delete Conversation
                    </a>
                </li>
                <li className={`${member_role?.role_id == 0 ? 'd-none' : ''}`}>
                    {" "}
                    <a href="javascript:void(0)" title="" onClick={() => leaveGroup(user.id)}>
                        <span>
                            <i className="fa-solid fa-sign-out-alt" />
                        </span>{" "}
                        Exit Group
                    </a>
                </li>
            </ul>
        </div>
        <AddGroupMember isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} group_id={user.id} />
        <ImageView images={image_array} isModalVisible={imageView_modalDialog} handleShowB={() => setImageViewModalDialog(false)} imageIndex={img_index} />
    </div>
    )
}
export default GroupChatFile;