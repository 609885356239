
import { createSlice } from '@reduxjs/toolkit';

export const suggest_userSlice = createSlice({
    name:'suggested_user',
    initialState:{
        loading: false,
        data: [],
        error: false,
    },
    reducers:{
        SUGGESTED_USER_FETCH_START:(state)=>{
            state.loading=true
        },
        SUGGESTED_USER_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        SUGGESTED_USER_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { SUGGESTED_USER_FETCH_START,SUGGESTED_USER_FETCH_SUCCESS,SUGGESTED_USER_FETCH_ERROR }=suggest_userSlice.actions;
export default suggest_userSlice.reducer;