import { Modal, ModalBody, ModalFooter } from "reactstrap";
import TimeZone from "../../CustomHooks/TimeZone";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";
import API from "../../Network/API";
import { format } from 'date-fns';

export default function EditMeeting(props) {
    const [check_box,setCheckbox]=useState(false)
    const [time_zone, setTimeZone] = useState('Europe/London');
    const [time, setTime] = useState(null);
    const usert_timez = useSelector((state) => state.timeZoneReducer)
    const [timezonename, setTimezoneText] = useState('');
    const [loading, setLoading] = useState(false);
    const getCurrentTimezone = () => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimezoneText(userTimezone);
    };
    const fileObj = [];

    const handleSubmit = event => {
        event.preventDefault();
        if (typeof title === null) {
            toast.error('Please add title.');
            return false;
        }
        if (typeof time_zone === null) {
            toast.error('Please add time_zone.');
            return false;
        }
        const fd = new FormData();
        fd.append('title', event.target.title.value);
        fd.append('date', event.target.date.value);
        fd.append('time_zone', event.target.time_zone.value);
        fd.append('time', event.target.time.value);
        fd.append('note', event.target.note.value);
       if(event.target.is_notify.checked){
         fd.append('is_notify', 1);
       }
       if(event.target.is_recurring.checked){
        fd.append('is_repeated', 1);
  
        if(event.target.recurring_type.value==""){
          alert("Please select recurrence type");
          return;
        }
        fd.append('recurring_type', event.target.recurring_type.value);
  
      }else{
        fd.append('is_repeated', 0);
        fd.append('recurring_type', 0);
      }

        setLoading(true);
        if (fileObj.length > 0) {
            for (var i = 0; i < fileObj.length; i++) {
                fd.append('img', fileObj[i], fileObj[i].name);
            }
        }

        API.post(`/v3/meeting/update/${props?.meetingRoom?.id}`, fd).then(response => {
            setLoading(false);
            if (response.data.success === true) {
                props.handleShowB()
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.sms);
        });
    }

    const uploadHandler = (e) => {
        fileObj.pop();
        fileObj.push(e.target.files[0])
    }
    const startDate = new Date(props?.meetingRoom?.start_date);
    const formattedDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
    return (
        <>
            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
            >
                <ModalBody className="bg-light">
                    <h4 style={{ letterSpacing: '.03em', fontSize: '20px', color: '#131619', lineHeight: '32px' }}>Edit Meeting</h4>

                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit}>
                            <div className="meeting-invite-popup">
                                <div className="row mb-3 g-2">
                                    <p><span className="text-danger">*</span> marked are required</p>
                                    <div className="col-6">
                                        <label htmlFor="Title" className="form-label form-label-font-14">Meeting Title <span className="text-danger">*</span></label>
                                        <input type="text" defaultValue={props?.meetingRoom?.room_title} className="form-control form-control-font-12" id="Title" name="title" placeholder="Enter your meeting name" required />
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="date" className="form-label form-label-font-14">Date <span className="text-danger">*</span></label>
                                        <input type="date" defaultValue={formattedDate} name="date" className="form-control form-control-font-12" id="date" placeholder="Enter your meeting room" required />
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="time" className="form-label form-label-font-14">Time <span className="text-danger">*</span></label>
                                        <input type="time" defaultValue={props?.meetingRoom?.start_time} name="time" className="form-control form-control-font-12" id="time" placeholder="Enter your meeting room" required />
                                    </div>
                                </div>

                                <div className="row mb-3 g-2">
                                    <div className="col-6">
                                        <label htmlFor="Description" className="form-label form-label-font-14">Agenda</label>
                                        <input defaultValue={props?.meetingRoom?.note} name="note" type="text" className="form-control form-control-font-12" id="Description" placeholder="Meeting agenda" />
                                    </div>
                                    <div className="col-6">

                                        <label htmlFor="inputState" className="form-label form-label-font-14">Time Zone <span className="text-danger">*</span></label>
                                        <select name="time_zone"
                                            id="inputState"
                                            defaultValue={props?.meetingRoom?.time_zone}
                                            className="form-select form-control-font-12"
                                            required
                                        >
                                            {TimeZone()?.map((zoneName, index) => (
                                                <option
                                                    key={index}
                                                    value={zoneName}
                                                    selected={props?.meetingRoom?.time_zone === zoneName}
                                                >
                                                    {zoneName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="cvpotos" className="form-label form-label-font-14">Cover Photos</label>
                                        <div className="meeting-cover-photos position-relative" id="cvpotos">
                                            <input className="meeting-cover-photos-choose-file" type="file" onChange={uploadHandler} accept="image/*" />
                                            <div className="meeting-cover-photos-body">
                                                <div className="text-center">
                                                    <i className="fas fa-cloud-upload" />
                                                    <span>Drag an image here or upload a file</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input name="is_notify" className="form-check-input" type="checkbox"  id="flexCheckChecked"  />
                                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                                Notify Users
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-6">
                                        <div className="form-check">
                                        <input onChange={()=>setCheckbox(!check_box)} className="form-check-input" type="checkbox" id="is_recurring" name="is_recurring"  />
                                     
                                            <label className="form-check-label" htmlFor="is_recurring">
                                            Recurring Meeting
                                            </label>
                                        </div>
                                    </div>

                                    <div className={`col-6 ${check_box?'d-block':'d-none'}`}>
                
                                    <select className="form-select form-control-font-12" name="recurring_type" id="recurring_type">
                                        <option value={``}></option>
                                        <option value={1}>Daily</option>
                                        <option value={2}>Weekly</option>
                                        <option value={3}>Monthly</option>
                                    </select>
                                    
                                    </div>

                                </div>

                            </div>

                            <ModalFooter className={''}>
                                <button type="submit" className="btn btn-primary">Update</button>
                                <button type="button" className="btn btn-default" onClick={props.handleShowB}>Cancel</button>
                            </ModalFooter>

                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}