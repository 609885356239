
import { createSlice } from '@reduxjs/toolkit';

export const newFeatures_userSlice = createSlice({
    name: 'newFeatures',
    initialState: {
        loading: false,
        data: [],
        error: false,
    },
    reducers: {
        NEW_FEATURE_FETCH_START: (state) => {
            state.loading = true
        },
        NEW_FEATURE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        NEW_FEATURE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { NEW_FEATURE_FETCH_START, NEW_FEATURE_FETCH_SUCCESS, NEW_FEATURE_FETCH_ERROR } = newFeatures_userSlice.actions;
export default newFeatures_userSlice.reducer;