import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./assets/locales/en/translation.json";
import translationBN from "./assets/locales/bn/translation.json";
import translationPER from "./assets/locales/per/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  bn: {
    translation: translationBN,
  },
  per: {
    translation: translationPER
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;