import { createSlice } from '@reduxjs/toolkit';

export const postSlice = createSlice({
    name: 'post',
    initialState: {
        loading: true,
        demo_loading: true,
        data: {},
        main_data: {},
        post_data: [],
        error: false,
    },
    reducers: {
        POST_FETCH_START: (state) => {
            state.loading = true
        },
        POST_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.demo_loading = false
            state.data = action.payload
            state.main_data = action.payload
            state.post_data=action.payload.get_posts
        },
        POST_FETCH_SUCCESS_LOAD: (state, action) => {
            state.loading = false
            state.main_data = action.payload
            state.data = action.payload
            state.post_data=[ ...state.post_data, ...action.payload.get_posts]
        },
        ADD_POST_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.post_data=[action.payload,...state.post_data]
        },
        EDIT_POST_FETCH_SUCCESS: (state, action) => {
            console.log(action.payload);
            state.loading = false;
            const updatedPostIndex = state.post_data.findIndex(post => post.id === action.payload.id);
            if (updatedPostIndex !== -1) {
                const updatedPostArray = [...state.post_data];
                updatedPostArray[updatedPostIndex] = action.payload;
                state.post_data = updatedPostArray;
            }
        },
        POST_FETCH_COMMENT_ADDON: (state, action) => {
            // console.log(action.payload)
            state.loading = false
            state.post_data=state.post_data.map((post) =>
            post.id === action.payload.post_id
              ? post.last_comments.push(action.payload) && post
              : post
          )
        },
        

        DELETE_COMMENT:(state,action)=>{
            const Post = state.post_data.find(
                (pst) => pst.id === action.payload.post_id
              );
              
              Post.last_comments.filter((com)=>com.id !== action.payload.comment_id);
              state.loading = false
              state.post_data=state.post_data.map((pst) =>
              pst.id === action.payload.post_id ? Post : pst
            )
        },

        DELETE_POST:(state,action)=>{
            const filterPosts = state.post_data.filter(
                (post) => post.id !== action.payload
              );
              state.loading = false
            state.post_data=filterPosts
        },

        ADD_REPLY:(state,action)=>{
           
            state.post_data=state.post_data.map((post) =>
            post.id === action.payload.post_id
              ? post.last_comments.map((comment) =>comment.id === action.payload.comment_id?comment.comment_reply.push(action.payload):comment) && post
              : post
          )
             
        },

        DELETE_REPLY:(state,action)=>{
            
            const Post = state.post_data.find(
                (pst) => pst.id === action.payload.post_id
              );
              console.log('rp='+action.payload.comment_reply_id);
              Post.last_comments.map((com)=>com.comment_reply.splice(com.comment_reply.findIndex((fruit) => fruit.id === action.payload.comment_reply_id),1));
              
              state.post_data=state.post_data.map((pst) =>
              pst.id === action.payload.post_id ? Post : pst
            )
            
        },

        POST_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {EDIT_POST_FETCH_SUCCESS, POST_FETCH_START,DELETE_REPLY,ADD_POST_FETCH_SUCCESS, POST_FETCH_ERROR, POST_FETCH_SUCCESS,POST_FETCH_SUCCESS_LOAD,POST_FETCH_COMMENT_ADDON,ADD_REPLY,DELETE_COMMENT,DELETE_POST } = postSlice.actions;
export default postSlice.reducer;