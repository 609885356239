import SlideStory from "./Story/SlideStory";
import React, { Fragment, useEffect, useState, useCallback, useRef } from "react";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CreatePost from "./CreatePost";
import DivPlaceholder from "../Network/DivPlaceholder";

import { useDispatch, useSelector } from "react-redux";
import Agent from "../Network/Agent";
import { POST_FETCH_START } from "../Reducer/postReducer";
import SuggestedVideo from "./SuggestedVideo";

import PostNew from "../Props/PostNew";
import PostViewFull from "./Modal/PostViewFull";
toast.configure();


const Home = () => {
    const [postView_modalDialog, setPostViewModalDialog] = useState(false);
    const [post_data, setPostData] = useState(null);
    const [post_search_Id, setPostId] = useState(0);
    const [image_index, setImageIndex] = useState(null);
    const [demo_data, setDatas] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }]);
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const observer = useRef();
    const selector = useSelector((state) => state.postReducer)
 
    useEffect(() => {
        Agent.Articles.all(1, dispatch);
        Agent.User.myProfile(dispatch);
    }, []);

    useEffect(() => {
        var scroll_po=localStorage.getItem('scrollPosition');
        if(parseFloat(scroll_po)>0){
            window.scrollTo(0, parseFloat(scroll_po));
            localStorage.removeItem('scrollPosition');
        }
        
        // console.log(localStorage.getItem('scrollPosition'))
    }, []);

    useEffect(() => {
        if (page != 1) {
            dispatch(POST_FETCH_START())
            Agent.Articles.loadMore(page,dispatch)
        }

    }, [page]);


    const lastElementRef = useCallback((element) => {

        if (observer.current) observer.current.disconnect();

        if (!selector?.main_data.has_more_page) return;

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && selector?.main_data.has_more_page) setPage((prev) => prev + 1);
        });

        if (element) observer.current.observe(element);
    }, [selector?.main_data.has_more_page]);

    const postsData = selector?.post_data

    const get_post_data=(value)=>{
        setPostData(value);
        setPostId(value.id)
        // console.log(value.id);
    }

    const get_post_image_index=(value)=>{
        setImageIndex(value);
    }

    const togglePostView=(value)=>{
        setPostViewModalDialog(!postView_modalDialog);
    }

    return (
        <Fragment>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
                    <div className="story-postbox news-feed-area">
                        <SlideStory />
                        <div className="row justify-content-md-center">
                            <div className="col-md-10">

                                <CreatePost />
                            </div>
                        </div>
                        <div className="row justify-content-md-center">
                            <div className="col-md-10">
                                {selector?.demo_loading && demo_data?.map((post, i) => (
                                    <DivPlaceholder key={i} backgroundColor="#ffffff"
                                        foregroundColor="#ecebeb" />
                                ))}

                                {!selector?.demo_loading && postsData?.map((post, index) => (

                                  <>
                                    <div key={index} ref={index === postsData?.length - 1 ? lastElementRef : undefined}>
                                        <PostNew key={index} data={post} get_post_data={get_post_data} get_post_image_index={get_post_image_index} togglePostView={togglePostView}/>
                                    </div>
                                    {index==2?<SuggestedVideo/>:''}
                                  </>
                                    

                                ))}

                                <div className='loader' ></div>
                                {selector?.loading && <div className="text-center">Loading......</div>}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PostViewFull postId={post_search_Id}  isModalVisible={postView_modalDialog} handleShowB={() => setPostViewModalDialog(false)} imageIndex={image_index}/>
        </Fragment>
    )
}
export default Home;