import React from 'react'
import {Link} from "react-router-dom";

    // handle mouse enter
    const handleMouseEnter = (e) => {
        const vid = e.target
        vid.muted = true
        vid.play()
    }
    // handle mouse leave
    const handleMouseLeave = (e) => {
        const vid = e.target
        vid.muted = false
        vid.currentTime = 0
        vid.pause()
    }

const SuggestVideo = ({data}) => (

   <>
       <div className="single-video-card bg-white" key={Math.random()}>
           <div className="video-image ">
               {data.videos?.length > 0 ?
                   <div>
                       {data.videos?.slice(0).map((getVideo, i) => (
                           <video key={i}
                                        controls={false}
                                        preload="metadata"
                                        height="200"
                                        poster={`${getVideo.thumbnail}`}
                                        oncontextmenu="return false;"
                                        hideControls={['playbackrates']}
                                        src={`${getVideo.video_stream}`}
                                        className="w-100 video-js vjs-theme-sea suggested-side-video rounded-3"
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                           />

                       ))}
                       <div className="icon">
                           <a href={`/video/${data?.id}`} className="video-btn popup-youtube">
                               <i className="fa-solid fa-circle-play"/>
                           </a>
                       </div>

                   </div>
                   : ''}
           </div>
           <div className="video-info-area">
               <div className="video-info">
                   <div className="d-flex justify-content-between align-items-start">
                   <div className="d-flex justify-content-start">
                       <div className="video-user-container">
                           <Link to={`/contact/${data?.user_id}`}>
                           <div className="user-picture-container rounded-circle video-user-35" style={{backgroundImage: `url(${data?.avatar})`}} />
                           </Link>
                       </div>
                       <div className="text">
                            <h6 className="ms-2 me-2">
                                <Link to={`/video/${data.id}`}>{data.details !== "" ? data.details.substring(0, 20) : data.post_by}</Link>
                            </h6>
                            <div className="video-caption ms-2 me-2">
                                <p className="mb-2">{data.post_by}</p>
                            </div>
                            <div className="video-caption ms-2">
                                <p>{data.totalLikes} Loves
                                    <span className="circle"> <i className="fa-solid fa-circle"></i></span>
                                    <span>  {data.post_time}</span>
                                </p>

                            </div>
                        </div>
                   </div>
               </div>
               </div>
           </div>
       </div>
       </>
)
export default React.memo(SuggestVideo)