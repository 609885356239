
import HomeMeeting from "../HomeMeeting";
import Carousel from 'react-bootstrap/Carousel';
import React, {Component, Fragment, useEffect, useState, memo} from "react";
import API from "../../Network/API";
import RecentChat from "../RecentChat";
import ConnectSuggest from "../ConnectSuggest";
import ContentLoader from "react-content-loader"

export default function PublicRightSide(props) {
    const [WhatsNew, setWhatsNew] = useState([]);
    const [data_read, setData_read] = useState(false);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        setProgress(true)
        API.get(`/getWhatsNewFeatures`).then(response => {
            setWhatsNew(response.data.data)
            setProgress(false)
        })

    }, []);
    return(
        <Fragment>
            <div className="app-right-sidebar sidebar">
                <div className="sidebar-scroll">
                    <div className="widget-area">
                        <div className="widget widget-banner mb-4">
                    <h4 className="p-2">What’s new</h4>
                    <div className="widget-banner">
                        {progress ?
                            <ContentLoader
                                speed={2}
                                width={400}
                                height={142}
                                viewBox="0 0 400 142"
                                backgroundColor="#eeecec"
                                foregroundColor="#eae6e6"
                                {...props}
                            >
                                <rect x="0" y="0" rx="20" ry="20" width="300" height="142" />
                            </ContentLoader>
                            :

                        <Carousel fade interval={3500} controls={false} indicators={false}>
                            {!progress && WhatsNew.map((value, i) => (
                                <Carousel.Item key={i}>
                                    <div key={i} className="post-images" data-bs-toggle="modal" data-bs-target={`#WhatNewModal${i}`}>
                                        <img
                                            src={value.image}
                                            className="rounded-3 img-fluid" alt="image"/>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>

                        }
                    </div>
                </div>

                <div className="widget widget-who-following mb-4">
                <div className="public_post_login_area">
        <div className="public_post_login_area_heading">
          <h2>New to ConnectsApp?</h2>
        </div>
        <div className="public_post_login_area_subtitle">
          <span>Sign up now to get your own personalized timeline!</span>
        </div>
        <div className="public_post_login_area_button">
          <a href="/" className="btn public-post-btn-login">Log in</a>
          <a href="/" className="btn public-post-btn-signup">Sign up</a>
        </div>
        <div className="public_post_login_area_text">
          By signing up, you agree to the <a href>Terms of Service</a> and <a href>Privacy Policy</a>
        </div>
      </div>
      <div className="public-post-space"></div>
                </div>
            </div>
                </div>
                </div>

                <div className="bottom_public_post_login mobile-v-hide">
          <div className="bottom_public_post_login_background">
            <div className="bottom_public_post_login_padding">
              <div className="bottom_public_post_login_gap" />
              <div className="bottom_public_post_login_content d-flex justify-content-between align-items-center">
                <div className="bottom_public_post_login_content-left">
                  <h2 className="public_post_login_content-left-heading">Don’t miss what’s happening
                  </h2>
                  <p className="public_post_login_content-left-subHeading">People on ConnectsApp get the real news fast!.</p>
                </div>
                <div className="bottom_public_post_login_content-left">
                  <a className="btn btn-primary bottom_public_post_login-btn me-3" href="/" role="button">Log in</a>
                  <a className="btn btn-primary bottom_public_post_signup-btn" href="/" role="button">Sign up</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="public-post-logged-out-nav mobile-v-show bg-light">
          <div className="public-post-logged-out-nav-content">
            <div className="public-post-logged-out-nav-bottom-padding d-flex justify-content-between">
              <a className="btn public-post-logged-out-nav_login-btn public-post-logged-out-nav_common-btn me-3 w-100" href="/" role="button">Log in</a>
              <a className="btn public-post-logged-out-nav_signup-btn public-post-logged-out-nav_common-btn w-100" href="/" role="button">Sign up</a>
            </div>
          </div>
        </div>
                </Fragment>
    )
}