import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SETTINGS_FETCH_SUCCESS } from "../../Reducer/settingsReducer";
import API from "../../Network/API";
import { useTranslation } from "react-i18next";

const Settings = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.settingsReducer);
    const settingsData = selector?.data;
    const [loading, setLoading] = useState(false);

    const handleToggleSetting = async (settingKey) => {
        try {
            const updatedSettings = {
                [settingKey]: !settingsData[settingKey],
            };
            const response = await API.put('settings', updatedSettings);
            dispatch(SETTINGS_FETCH_SUCCESS(response.data.settings));
            console.log('Settings updated.');
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };

    const handleLanguageChange = async (event) => {
        const newLanguage = event.target.value;
        try {
            const updatedSettings = {
                language: newLanguage,
            };
            const response = await API.put('settings', updatedSettings);
            dispatch(SETTINGS_FETCH_SUCCESS(response.data.settings));
            console.log('Language updated.');
        } catch (error) {
            console.error('Error updating language:', error);
        }
    };


    return (
        <div className="col-md-8">
            <div className="setting-page">
                <div className="row">
                    <div className="setting-heading">
                        <h4>{t('Settings')}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="setting-item">

                        <div className="accordion accordion-flush setting-item-accordion" id="accordionSettings">

                            <div className="accordion-item single-setting-item">
                                <h2 className="accordion-header show" id="flush-headingOne">
                                    <button className="accordion-button collapsed single-setting-item-accordion" type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="true"
                                    aria-controls="flush-collapseOne"
                                    >
                                        <span className="single-setting-item-accordion-icon">
                                            <i className="fa-solid fa-circle-play" />
                                        </span>
                                        Video Autoplay
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse single-setting-item-accordion-body show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionSettings">
                                    <div className="accordion-body d-flex justify-content-between align-items-center">
                                        <span>Toggle to automatically play videos in your feed. Save data and prevent autoplay by turning it off.</span>
                                        <div className="customSwitchArea">
                                            <label className="customSwitch">
                                                <input type="checkbox" name="allowVideoAutoPlay" onChange={() => handleToggleSetting('allowVideoAutoPlay')} checked={settingsData?.allowVideoAutoPlay ?? true} />
                                                <span className="customSlider customRound"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item single-setting-item">
                                <h2 className="accordion-header show" id="flush-headingTwo">
                                    <button className="accordion-button collapsed single-setting-item-accordion" type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                    >
                                        <span className="single-setting-item-accordion-icon">
                                            <i className="fa-solid fa-volume-low" />
                                        </span>
                                        Sounds
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse single-setting-item-accordion-body" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionSettings">
                                    <div className="accordion-body d-flex justify-content-between align-items-center">
                                        <span>Toggle to enable or disable notification sounds for incoming messages.</span>

                                        <div className="customSwitchArea">
                                            <label className="customSwitch">
                                                <input type="checkbox" name="allowMessagingSound" onChange={() => handleToggleSetting('allowMessagingSound')} checked={settingsData?.allowMessagingSound ?? true} />
                                                <span className="customSlider customRound"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="accordion-body d-flex justify-content-between align-items-center">
                                        <span>Toggle to enable or disable notification sounds for all alerts and updates.</span>

                                        <div className="customSwitchArea">
                                            <label className="customSwitch">
                                                <input type="checkbox" name="allowNotificationSound" onChange={() => handleToggleSetting('allowNotificationSound')} checked={settingsData?.allowNotificationSound ?? true} />
                                                <span className="customSlider customRound"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item single-setting-item">
                                <h2 className="accordion-header show" id="flush-heading3">
                                    <button className="accordion-button collapsed single-setting-item-accordion" type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse3"
                                    aria-expanded="false"
                                    aria-controls="flush-collapse3"
                                    >
                                        <span className="single-setting-item-accordion-icon">
                                            <i className="fa-solid fa-language" />
                                        </span>
                                        Language
                                    </button>
                                </h2>
                                <div id="flush-collapse3" className="accordion-collapse collapse single-setting-item-accordion-body" aria-labelledby="flush-heading3" data-bs-parent="#accordionSettings">
                                    <div className="accordion-body d-flex justify-content-between align-items-center">
                                        <span>Set your preferred language.</span>

                                        <div className="">
                                            <select class="form-select" aria-label="Default English"
                                            value={settingsData?.language}
                                            onChange={handleLanguageChange}
                                            >
                                                <option value="en">English</option>
                                                <option value="bn">Bengali</option>
                                                <option value="per">Persian</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Settings;