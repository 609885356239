
import { toast } from 'react-toastify';
import React, { useState, useEffect } from "react";
import API from "../../Network/API";
import { Link } from "react-router-dom";
import AddMeetingSchedule from "../Modal/AddMeetingSchedule";
import MeetingShare from "../Modal/MeetingShare";
import ContentLoader from "react-content-loader"
import ShareMeeting from '../Modal/ShareMeeting';
import { useSelector } from 'react-redux';
import EditMeeting from '../Modal/EditMeeting';
import { useTranslation } from "react-i18next";

toast.configure();
export default function Meeting() {
    const {t} = useTranslation()
    const [meetingId, setMeetingId] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalDialog, setModalDialog] = useState(false);
    const [addModalDialog, setAddModalDialog] = useState(false);
    const [sendToShareData, setSendToShareData] = useState(null);
    const [meetingList, setMeetingList] = useState([]);

    const [isMeetingLoading, setMeetingLoading] = useState(false);
    const [demo_data, setDatas] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }]);
    const [progress, setProgress] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [next_page, setNextPage] = useState('/v1/upcoming_meeting_all');
    const [nextPageUrl, setNextPageURL] = useState(null);
    const [lengthData, setDataLength] = useState(0);
    const myArr = JSON.parse(localStorage.getItem('user_data'));
    const [modalDialogShare, setModalDialogShare] = useState(false);
    const [sc_meet, setScMeet] = useState(null);
    const [edit_modalDialog, setEditModalDialog] = useState(false);
    const usert_timez = useSelector((state) => state.timeZoneReducer)
    const toggleModalSahre = (meeting_room) => {
        setScMeet(meeting_room)
        setModalDialogShare(!modalDialogShare)
    };

    const toggleEditMeeting = (meeting_room) => {
        setScMeet(meeting_room)
        setEditModalDialog(!modalDialog)
    };

    useEffect(() => {
        fetchRepositories()
    }, []);

    function fetchRepositories() {

        setProgress(true)

        API.get(next_page, {
            'offset': meetingList.length
        }).then((response) => {

            const paginator = response.data.data;

            setMeetingList(response.data.data.meeting_data)
            setProgress(false)
            setNextPageURL(paginator.next_page_url)
            setPage((page + 1))


        }).catch((error) => {
            setProgress(true)

        });

    }

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    };

    const toggleModal1 = () => {
        setAddModalDialog(!addModalDialog)
    };

    const _new_meeting = () => {
        setLoading(true);
        API.get(`/v2/createRoomJson?timezone=${usert_timez.timezone}&datetime=${usert_timez.datetime}`).then(res => {
            setSendToShareData(res.data)
            toggleModal()
            // _start_meeting(res.data.room);
        });
    }
    const _start_meeting = (meeting_id) => {
        if (meeting_id) {
            window.location.href = "/meeting/" + meeting_id;
        } else {
            toast.error("Please enter meeting id");
        }
    }

    function scheduleStart(meeting_id) {
        API.get('/meeting/' + meeting_id).then(res => {
            window.location.href = "/meeting/" + meeting_id;
        }).catch((e) => {
            // console.log(e);
        });
    }

    // const meeting_delete = (m_id) => {
    //     var result = window.confirm("Are you sure want to delete?");
    //     if (result == true) {
    //       setLoading(true)
    //       API.delete("/meeting_delete/" + m_id).then((response) => {
    //         setLoading(false)
    //         if (response.data.success === true) {
    //           toast.success('delete successfully');
    //         }
    //       });
    //     }

    //   }
    return (
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
                    <div className="meeting-page">
                        <div className="row -g-2">
                            {/* <div className="col-md-8 col-8">
                                <div className="meeting-headding-txt mb-3">
                                    <h1>
                                        Connects <span>Meeting</span>
                                    </h1>
                                </div>

                            </div> */}
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-md-8 col-sm-10 col-xl-12 col-lg-12 col-12 p-0'>
                                <div className="meeting-main-section">
                                    <div className="meeting-input mb-3 ">

                                        <div className="d-flex flex-column flex-md-row mb-3 position-relative">
                                            <div className={`dropdown m-2 meeting-create-dp w-xs-100 w-30`}>
                                                <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary dropdown-toggle meeting-create-btn w-100" // Ensures full width on smaller screens
                                                    aria-expanded="false"
                                                    data-bs-toggle="dropdown"
                                                    id={`dropdownMenuLink`}
                                                >
                                                    <i className="fa-solid fa-video me-1" />
                                                    {t('New Meeting')}
                                                </button>
                                                <div
                                                    className="meeting-icon-popup shadow-sm position-absolute dropdown-menu"
                                                    aria-labelledby="dropdownMenuLink"
                                                >
                                                    <div>
                                                        <a
                                                            onClick={() => _new_meeting()}
                                                            className="clickable d-flex meeting-action-item align-items-center"
                                                        >
                                                            <i className="far fa-plus" /> <span className="ms-2">{t('Start an instant meeting')}</span>
                                                        </a>
                                                        <a
                                                            className="clickable d-flex meeting-action-item align-items-center"
                                                            onClick={() => toggleModal1()}
                                                        >
                                                            <i className="fas fa-calendar-alt" /> <span className="ms-2">{t('Create a meeting for later')}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <input
                                                type="text"
                                                className="meeting-form-control meeting-link-input m-2 w-xs-100 w-30"
                                                id="link"
                                                placeholder="Meeting ID"
                                                spellCheck="false"
                                                defaultValue={""}
                                                onChange={(e) => setMeetingId(e.target.value)}
                                            />
                                            {meetingId === "" ? (
                                                <button
                                                    type="button"
                                                    onClick={() => _start_meeting(meetingId)}
                                                    className="btn btn-secondary m-2 btn-join w-xs-100 w-30"
                                                    disabled
                                                >
                                                    {t('Join Meeting')}
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={() => _start_meeting(meetingId)}
                                                    className="btn btn-secondary m-2 btn-join w-xs-100 w-30"
                                                >
                                                   {t('Join Meeting')}
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {meetingList.length > 0 ?
                            <div className='row mt-4'>

                                <div className="col-12">
                                    <div className="mb-2">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5>{t('Meetings')}</h5>
                                            <Link to={`/meeting-history`} type="button"
                                                className="btn btn-outline-dark btn-schedule p-2 mx-3">
                                                <i className="fa-solid fa-clock-rotate-left" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {progress && demo_data?.map((post, i) => (
                                    <ContentLoader
                                        speed={2}
                                        width={400}
                                        height={80}
                                        viewBox="0 0 500 80"
                                        backgroundColor="#dfdddd"
                                        foregroundColor="#ebebeb"

                                    >
                                        <circle cx="36" cy="55" r="17" />
                                        <rect x="81" y="43" rx="0" ry="0" width="327" height="8" />
                                        <rect x="82" y="57" rx="0" ry="0" width="271" height="7" />
                                        <circle cx="52" cy="55" r="17" />
                                    </ContentLoader>
                                ))}
                                {!progress && meetingList?.map((value, index) => (
                                    <div className="col-12">
                                        <div className="card mb-3 border-0 shadow-sm">
                                            <div className="card-body py-2">
                                                <div className="upcoming-meeting-list upcoming-meeting-page-list row align-items-center">
                                                    <div className="col-md-7 col-sm-7">
                                                        <div className="upcoming-meeting-page-content-left d-flex align-items-center">
                                                            <div className="upcoming-meeting-date_time">
                                                                <h2 className="upcoming-meeting-date">{value.day}</h2>
                                                                <p className="upcoming-meeting-month">{value.month}</p>
                                                                <p className="upcoming-meeting-time">{value.time}</p>
                                                            </div>
                                                            <div className="upcoming-meeting-content">
                                                                <h4 className="upcoming-meeting-title">
                                                                    {value.room_title}
                                                                </h4>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="upcoming-meeting-user-list">
                                                                        <div className="upcoming-meeting-user-img d-flex align-items-center">
                                                                            <ul>
                                                                                <>
                                                                                    {value.users.slice(0, 3).map((user, index) => (

                                                                                        <li>
                                                                                            <Link to={`/contact/${user.id}`}><div className="user-picture-30 rounded-circle" style={{ background: `url(${user.avatar})` }} /></Link>

                                                                                        </li>

                                                                                    ))}
                                                                                </>
                                                                                {value.users.length > 3 ?
                                                                                    <li>
                                                                                        <Link to={`/meeting-details/${value.room_name}`}>
                                                                                            <div className="upcoming-meeting-user-count">+{value.users.length - 3}</div>
                                                                                        </Link>
                                                                                    </li>
                                                                                    : ''}
                                                                            </ul>
                                                                            
                                                                            {value.is_owner == 1 ?
                                                                                <a href="javascript:void(0)" className="btn" onClick={() => toggleModalSahre(value)}>
                                                                                    <i className="fa fa-plus" />
                                                                                 </a>
                                                                            : ''}
                                                                            {value.is_owner == 1 ?
                                                                                <i className="fa fa-edit clickable" onClick={() => toggleEditMeeting(value)} />
                                                                            : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5 col-sm-5">
                                                        <div className="upcoming-meeting-page-content-right d-flex align-items-center justify-content-between">
                                                            <div className="upcoming-meeting-content-time main-color d-flex align-items-center">
                                                                <span>{value.created_time}</span>
                                                            </div>
                                                            <div className="upcoming-meeting-content-details text-secondary ms-3">

                                                                <Link to={`/meeting-details/${value.room_name}`}>
                                                                    <button className='btn btn-outline-dark mx-2'>{t('Details')}</button>
                                                                </Link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>
            <AddMeetingSchedule isModalVisible={addModalDialog} handleShowB={() => setAddModalDialog(false)} />
            <MeetingShare modalVisible={modalDialog} handleShow={() => setModalDialog(false)} shareData={sendToShareData} type={1} />
            <ShareMeeting isModalVisible={modalDialogShare} meetingRoom={sc_meet} handleShowB={() => setModalDialogShare(false)} />
            <EditMeeting isModalVisible={edit_modalDialog} meetingRoom={sc_meet} handleShowB={() => setEditModalDialog(false)} />
        </>
    )
}