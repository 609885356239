import API from "../Network/API";
import React, { Component, Fragment, useEffect, useState, memo } from "react";
import PlaceholderSuggestFriend from "../Network/PlaceholderSuggestFriend";
import ContentLoader from "react-content-loader"
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function HomeMeeting(props) {
    const {t} = useTranslation()
    const [demo_data, setDatas] = useState([{ id: 1 }, { id: 2 }, { id: 3 }]);
  const [MeetingList, setMeetingList] = useState([]);
  const [data_read, setData_read] = useState(false);
  const [progress, setProgress] = useState(false);
  const selector = useSelector((state) => state.upcomingMeetingReducer)

 

  return (
    <>
   
    {!selector?.loading && selector?.data?.length>0?
    <div className="widget widget-meeting-history mb-4">
      <h4>{t('Meetings')}</h4>
      <div className="overflow-hidden">
        <div className="meeting-history">
          <ul className="list-group list-group-flush">


            {selector?.loading && demo_data?.map((post, i) => (
              <li key={i} className="list-group-item widget-item-list ">
                <ContentLoader
                  speed={2}
                  width={400}
                  height={60}
                  viewBox="0 0 400 60"
                  backgroundColor="#eeecec"
                  foregroundColor="#eae6e6"
                  {...props}
                >
                  <circle cx="31" cy="31" r="15" />
                  <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
                  <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
                </ContentLoader>
              </li>
            ))}
            {
              !selector?.loading && selector?.data?.map((value, index) => (

                <li className="list-group-item" key={value.id}>
                  <div className="upcoming-meeting-list d-flex align-items-center">
                    <div className="upcoming-meeting-date_time">
                      <h2 className="upcoming-meeting-date">{value.day}</h2>
                      <p className="upcoming-meeting-month">{value.month}</p>
                      <p className="upcoming-meeting-time">{value.time}</p>
                    </div>

                    <div className="upcoming-meeting-content">
                      <h4 className="upcoming-meeting-title">
                      <Link to={`/meeting-details/${value.room_name}`}>{value.room_title}</Link>
                      </h4>
                      <div className="upcoming-meeting-user-list">
                        <div className="upcoming-meeting-user-img">
                          <ul>
                            <>
                            {value.users.slice(0, 3).map((user, index) => (
                              <li key={user.id}>
                                <Link to={`/contact/${user.id}`}>
                                  <div
                                    className="user-picture-30 rounded-circle"
                                    style={{ background: `url(${user.avatar})` }}
                                  />
                                </Link>
                              </li>
                            ))}
                            </>
                            {value.users.length > 3 ?
                              <li>
                                <Link to={`/meeting-details/${value.room_name}`}>
                                  <div className="upcoming-meeting-user-count">+{value.users.length - 3}</div>
                                </Link>
                              </li>
                              : ''}
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
    :''}
    </>
  )
}