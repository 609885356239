import React, { Fragment, useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../Network/API";
import { toast } from "react-toastify";
import VideoJS from "./VideoJS";
import { useTranslation } from "react-i18next";
toast.configure();

export default function PostShareModal(props) {
    // { isModalVisible, handleShowB,postId,post_data }
    const {t} = useTranslation()
    const [modalDialog, setModalDialog] = useState(false);
    const [share_comment, setShareComment] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const toggleModal = () => {
        setModalDialog(!modalDialog)
    };
    const myArr = JSON.parse(localStorage.getItem('user_data'));
    const post_data = props.post_data
    
    const share_post = (p_id) => {

        setLoading(true)
        API.post("/share_friend_post", {
            post_id: p_id,
            details: share_comment,
        }).then((response) => {
            setLoading(false)

            if (response.data.success === true) {
                setShareComment("")
                props.handleShowB()
                toast.success('Post Shared')
            }
        }).catch((err) => {
            setLoading(false)
            props.handleShowB()
        });
    }
    return (
        <>
            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'}
            >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    {t('Share to your timeline')}
                </ModalHeader>
                <ModalBody>

                    <div >
                        <div className="share-creation-state">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="image">
                                    <a href="javascript:void(0)">
                                        <div className="user-picture-container rounded-circle" style={{ background: `url(${myArr?.avatar})` }} />
                                    </a>
                                </div>
                                <div className="info ms-2">
                                    <span className="name">
                                        <a href="javascript:void(0)" className="text-dark">{myArr.name}</a>
                                    </span>
                                    <div className="dropdown">
                                        {/* <button className="btn btn-share_post_privacy dropdown-toggle rounded-pill border border-dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa-solid fa-globe me-1" /> Everyone
                                        </button> */}
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" href="javascript:void(0)">Public</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="share_post_write_text">
                            <form>
                                <div className="form-group">
                                    <textarea name="message" onChange={(e) => setShareComment(e.target.value)} className="form-control write_textarea" placeholder={t('Write something here')} spellCheck="false" defaultValue={""} />
                                </div>
                            </form>
                        </div>
                        <div className="share_post_share_content">
                            <div className="news-feed news-feed-post bg-white shadow mb-3">
                                <div className="post-header d-flex  align-items-center">
                                    <div className="image">
                                        <a href="javascript:void(0)">
                                            <div className="user-picture-container rounded-circle" style={{ backgroundImage: `url(${post_data.avatar})` }} />
                                        </a>
                                    </div>
                                    <div className="info ms-3">
                                        <span className="name">
                                            <a href={`/contact/${post_data.user_id}`}>{post_data.post_by}
                                                {post_data.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                            </a>
                                        </span>
                                        <span className="small-text"><a href="javascript:void(0)">{post_data.post_time}</a></span>
                                    </div>
                                </div>
                                <div className="post-body">
                                    <p dir="auto" className="post-text">{post_data.is_shared_post ? post_data.self_details : post_data.details}</p>
                                    {
                                        post_data.images?.length > 0 ?
                                            <>
                                                <div className="post-image">
                                                    <div className="d-block p-0 mb-3">
                                                        <div className="row ps-2 pe-2">
                                                            {post_data.images?.map((postImage, i) => (
                                                                <div key={i}>
                                                                    {post_data.images?.length <= 1 ?
                                                                        <div className={`col-12   p-1 text-center`}>
                                                                            <img src={postImage.image} />
                                                                        </div> :
                                                                        <>
                                                                            {post_data.images?.length === 2 ?
                                                                                <div className={`col-6  p-1`}>

                                                                                    <div className="frame_img_height frame_img"
                                                                                        style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                    {/*<Photo className="frame_img_height frame_img" key={i} {...postImage} {...i}/>*/}

                                                                                </div> :
                                                                                <>
                                                                                    {post_data.images?.length === 3 ?
                                                                                        <>

                                                                                            <div className={`${i === 0 ? 'col-12' : 'col-6'}  p-1`}>
                                                                                                <div className={`${i === 0 ? 'frame_img_height3' : 'frame_img_height'}  frame_img`}
                                                                                                    style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                            </div>


                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {post_data.images?.length === 4 ?
                                                                                                <div className={`col-6  p-1`}>
                                                                                                    <div className="frame_img_height frame_img"
                                                                                                        style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                                </div>
                                                                                                :
                                                                                                <>
                                                                                                    {post_data.images?.length >= 5 ?
                                                                                                        <>
                                                                                                            {i <= 4 ?
                                                                                                                <div
                                                                                                                    className={`${i <= 1 ? 'col-6' : 'col-4'}  p-1`}>
                                                                                                                    <>
                                                                                                                        {i == 4 ?
                                                                                                                            <a href='#' className={'position-relative d-block'}>
                                                                                                                                <div
                                                                                                                                    className={`${i <= 1 ? 'frame_img_height frame_img' : 'frame_img_height113 frame_img'}`}
                                                                                                                                    style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                                                                <span
                                                                                                                                    className="img-count "><b>+{(post_data.images?.length - 5)}</b></span>
                                                                                                                            </a>
                                                                                                                            :
                                                                                                                            <div
                                                                                                                                className={`${i <= 1 ? 'frame_img_height frame_img' : 'frame_img_height113 frame_img'}`}
                                                                                                                                style={{ backgroundImage: `url(${postImage.image})` }} />
                                                                                                                        }


                                                                                                                    </>


                                                                                                                </div> : ''
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <>

                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            ''}

                                    <div className="post-image" key={post_data.id}>

                                        {post_data.videos?.length > 0 ?
                                            <div>
                                               
                                                {post_data.videos.map((getVideo, i) => (
                                                    <div key={i}>
                                                    <VideoJS
                                                        key={i}
                                                        options={{
                                                            controls: true,
                                                            preload: 'metadata',
                                                            height:'300',
                                                            poster: getVideo.thumbnail,
                                                            sources: [{
                                                            src: getVideo.video_stream,
                                                            type: 'video/mp4'
                                                            }]
                                                        }}
                                                        enableScrollPause={false}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            : ''}

                                    </div>

                                    {post_data.url_details != null && post_data.url_details['image'] != null ?
                        <>
                            <a href={post_data.url_details['url']} target="_blank" rel="ugc" className="w-100">
                                {post_data.url_details['image'] != null ?
                                    <div className="post-image">
                                        <img className="img-fluid w-100" src={post_data.url_details['image']} alt="image" />
                                    </div>
                                    : ''
                                }


                                <div className="share-link-post">
                                    <div className="share-link-post-content">
                                        <h5 className="share-link-post-content-heading">
                                            {post_data.url_details['title']}
                                        </h5>

                                        <p className="share-link-post-content-disc">
                                            {post_data.url_details['description']}
                                        </p>
                                    </div>

                                </div>
                            </a>
                        </>
                        : ''
                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className={'post-footer-comment-icon'}>
                    <button onClick={() => share_post(props.postId)} className="btn btn-primary rounded-1" >{t('Post')} {isLoading ? (
                        <span
                            className="spinner-border spinner-border-sm ml-5"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    ) : (
                        <span></span>
                    )}</button>

                </ModalFooter>
            </Modal>
        </>
    )
}