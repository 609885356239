import { useState } from "react";
import { useSelector } from "react-redux"
import SuggestRealModal from "./Modal/SuggestRealModal";
import { Link, useNavigate } from "react-router-dom";
import RealsCreateModal from "./Modal/RealsCreateModal";
import { useTranslation } from "react-i18next";

export default function SuggestedVideo() {
    const {t} = useTranslation()
    const navigate=useNavigate();
    const [modalDialog, setModalDialog] = useState(false);
    const [realModalDialog, setRealsModalDialog] = useState(false);
    const [videoIndex, setVideo] = useState();
    const [video_url, setVideoUrl] = useState();
    const selector_video = useSelector((state) => state.suggestedVideoReducer)
    const postsData = selector_video?.post_data;
    const handleMouseEnter = (e) => {
        const vid = e.target
        vid.muted = true
        vid.play()
    }
    // handle mouse leave
    const handleMouseLeave = (e) => {
        const vid = e.target
        vid.muted = false
        vid.currentTime = 0
        vid.pause()
    }

    const toggleModal = (url,pdata) => {
        setVideo(pdata)
        setVideoUrl(url)
        setModalDialog(!modalDialog)
    };
   
    const toggleCreateModal = () => {
        setRealsModalDialog(!realModalDialog)
    };

    
    const openStory = (story_id,index) => {
        const url = `/shorts/${story_id}`;
        const scrollPosition = window.scrollY;
        // localStorage.removeItem('scrollPosition');
        localStorage.setItem('scrollPosition', scrollPosition);
        // console.log(scrollPosition)
        navigate(url);
    };

    return (
        <>
            <div className="news-feed news-feed-post bg-white shadow-sm mb-3">
                <div className="Promoted_Post_content mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="content">
                            <h6> <i className="far fa-play-circle" />
                                <span className="ms-1">{t('Suggested Shorts')}</span></h6>
                        </div>
                        <div className="global-dropdown">
                            <div className="dropdown">
                                <a onClick={()=>toggleCreateModal()} className="btn btn-sm btn-outline-primary rounded-circle"><i className="fa-solid fa-plus pt-1"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="post-body position-relative">
                    <div className="reel-next-btn-wrap">
                        <div className="reel-next-btn shadow-sm clickable">
                            <Link to={'/videos'}><i className="fa-solid fa-angle-right" /></Link>
                        </div>
                    </div>
                    <div className="row">
                        {postsData?.length > 0 && postsData?.slice(0, 4).map((post, index) => (

                            <>
                                {post.videos.length > 0 ?
                                    <>
                                        {post.videos.map((getVideo, i) => (
                                            <div className={`col-md-3 col-4 col-sm-4 p-1 ${index==3?'d-none d-md-block':''}`}>
                                                <div className="story-box reel-box">
                                                {/* onClick={()=>toggleModal(getVideo.video_stream,post.id)} */}
                                                    <div className="video-image"  onClick={() => openStory(post.id,index)}>
                                                        <video key={i}
                                                            controls={false}
                                                            preload="metadata"
                                                            type="video/mp4"
                                                            height="215"
                                                            poster={`${getVideo.thumbnail}`}
                                                            oncontextmenu="return false;"
                                                            hideControls={['playbackrates']}
                                                            src={`${getVideo.video_stream}`}
                                                            className="w-100 video-hover-play"
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                        //  className="w-100 video-js vjs-theme-sea"
                                                        />
                                                        {/* onClick={()=>toggleModal(post.videos,getVideo)} */}
                                                        <div className="icon">
                                                            <a href="javascript:void(0)"  onClick={() => openStory(post.id,index)} className="video-btn popup-youtube">
                                                                <i className="fa-solid fa-circle-play" onClick={() => openStory(post.id,index)}/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    : ''
                                }
                            </>
                        ))}

                    </div>
                    <div className="row">
                        <Link to={`/videos`} className="d-block btn btn-sm text-primary border-0 fw-bold">{t('See More')}</Link>
                    </div>
                </div>
            </div>
            <SuggestRealModal isModalVisible={modalDialog}  handleShowB={() => setModalDialog(false)} postId={videoIndex} video_url={video_url} />
            <RealsCreateModal isModalVisible={realModalDialog} handleShowB={()=>setRealsModalDialog(false)}/>
        </>
    )
}