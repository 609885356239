import React, { useEffect, useState } from "react";
import API from "../Network/API";
import { useParams } from "react-router-dom";

import { SINGLE_POST_FETCH_ERROR, SINGLE_POST_FETCH_SUCCESS } from "../Reducer/singlePostReducer";
import { useDispatch, useSelector } from "react-redux";
import PublicPost from "../Props/PublicPost";
import PostLoader from '../assets/img/loader.gif';
import PostNew from "../Props/PostNew";
import PostViewSinglePost from "./Modal/PostViewSinglePost";
export default function SinglePost() {

    let { id } = useParams();
    const dispatch = useDispatch();
    const auth_user = useSelector((state) => state.authReducer)
    const [isLoading, setLoading] = useState(true);
    const [postView_modalDialog, setPostViewModalDialog] = useState(false);
    const [post_data, setPostData] = useState(null);
    const [post_search_Id, setPostId] = useState(0);
    const [image_index, setImageIndex] = useState(null);

    function useAuth() {
        if (localStorage.getItem('connect_token') && localStorage.getItem('connect_token') !== 'undefined') {
            return true;
        }
        return false;
    }
    const auth = useAuth();

    useEffect(() => {
        setLoading(true)

        if (!auth) {
            API.get(`/v2/get_post/${id}`).then(response => {
                dispatch(SINGLE_POST_FETCH_SUCCESS(response.data.data));
                setLoading(false)
            }).catch((error) => {
                dispatch(SINGLE_POST_FETCH_ERROR());
            });

        } else {
            API.get(`/get_post_comments/${id}`).then(response => {
                dispatch(SINGLE_POST_FETCH_SUCCESS(response.data.data));
                setLoading(false)
            }).catch((error) => {
                dispatch(SINGLE_POST_FETCH_ERROR());
            });
        }


    }, [id, auth_user?.accessToken]);

    const get_post_data = (value) => {
        setPostData(value);
        setPostId(value.id)
    }

    const get_post_image_index = (value) => {
        setImageIndex(value);
    }

    const togglePostView = (value) => {
        setPostViewModalDialog(!postView_modalDialog);
    }


    const state = useSelector((state) => state.singlePostReducer)

    return (
        <>

            {isLoading ?
                <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center' }}>
                    <img src={PostLoader} alt='Post loader' height={80} width={80} />
                </div>
                :
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10">
                        <div className="story-postbox news-feed-area">
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    {!auth ?
                                        <PublicPost key={1} data={state.post_data} />
                                        :
                                        <PostNew key={1} data={state.post_data} get_post_data={get_post_data} get_post_image_index={get_post_image_index} togglePostView={togglePostView} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <PostViewSinglePost postId={post_search_Id} isModalVisible={postView_modalDialog} handleShowB={() => setPostViewModalDialog(false)} imageIndex={image_index} />

        </>
    )

}