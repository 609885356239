
import { createSlice } from '@reduxjs/toolkit';

export const upcomingMeeting_userSlice = createSlice({
    name: 'upcomingMeeting',
    initialState: {
        loading: false,
        data: [],
        error: false,
    },
    reducers: {
        UPCOMING_MEETING_FETCH_START: (state) => {
            state.loading = true
        },
        UPCOMING_MEETING_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        UPCOMING_MEETING_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { UPCOMING_MEETING_FETCH_START, UPCOMING_MEETING_FETCH_SUCCESS, UPCOMING_MEETING_FETCH_ERROR } = upcomingMeeting_userSlice.actions;
export default upcomingMeeting_userSlice.reducer;