import React, { useState, useEffect } from 'react';

export default function PopupNewPost(Props){
    const [showButton, setShowButton] = useState(true);

    useEffect(() => {
      const handleScroll = () => {
        setShowButton(window.scrollY > 300);
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const topRef = React.createRef();
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    return(
        <>
        {showButton && (
            <div className="PopupNewPost">
                <button className="btn btn-primary rounded-pill PopupNewPostBtn shadow" onClick={scrollToTop}>New Post 
                    <span className='PopupNewPostBg rounded-circle' style={{ backgroundImage: `url(${Props.image})`}}></span> 
                    <i className="fa-solid fa-arrow-up fa-sm"></i> 
                </button>
            </div>
          )}
          
          <div ref={topRef} />
        </>
    );
}
