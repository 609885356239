
import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

export const settingsSlice = createSlice({
    name:'settings',
    initialState:{
        loading: false,
        data: {

        },
        error: false,
    },
    reducers:{
        SETTINGS_FETCH_START:(state)=>{
            state.loading=true
        },
        SETTINGS_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            i18next.changeLanguage(action.payload.language)
        },
        SETTINGS_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { SETTINGS_FETCH_START, SETTINGS_FETCH_ERROR, SETTINGS_FETCH_SUCCESS }=settingsSlice.actions;
export default settingsSlice.reducer;