import React from "react";
const NotificationList = (props) => (
        <>
            {props.notification?.map((value, index) => (
            <li key={index} className={`list-group-item widget-item-list notification-meta ${value.read_at == null ? "notification-unseen-color" : "" }`}>
                <div className="recent-chat-item d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start">
                        <div className="recent-chat-user-img position-relative">
                        <a href={
                                value.type == 4 ? `/contact/${value.app_url}` : 
                                value.type == 0 ? `/meeting-details/${value.app_url}` : 
                                value.type == 6 ? `/meeting-details/${value.app_url}` : 
                                value.type == 7 ? `/stories/${value.app_url}` :  
                                `/post/${value.app_url}`
                                }>
                                <div className="user-picture-container rounded-circle" style={{background: `url("${value.avatar}")`}} />
                                {value.type === 4 ?
                                <div className="notification-notify-icon position-absolute bg-success">
                                    <i className="fa-solid fa-user-plus"/>
                                </div>
                                : value.type === 3 ?
                                <div className="notification-notify-icon position-absolute bg-primary">
                                    <i className="fa-solid fa-share"/>
                                </div>
                                : value.type === 2 ?
                                <div className="notification-notify-icon position-absolute bg-primary">
                                    <i className="fa-solid fa-comment"/>
                                </div>
                                : value.type === 1 ?
                                 <div className="notification-notify-icon position-absolute bg-danger">
                                    <i className="fa-solid fa-heart"/>
                                </div>
                                : value.type === 0 ?
                                <div className="notification-notify-icon position-absolute bg-danger">
                                   <i className="fa-solid fa-video"/>
                               </div>
                               :
                               ''
                           }
                        </a>
                        </div>
                        <div className="notification-content ms-3">
                        <a href={
                                value.type == 4 ? `/contact/${value.app_url}` : 
                                value.type == 0 ? `/meeting-details/${value.app_url}` : 
                                value.type == 6 ? `/meeting-details/${value.app_url}` : 
                                value.type == 7 ? `/stories/${value.app_url}` :  
                                `/post/${value.app_url}`
                                }
                            >
                            <p>{value.title} </p>
                            <span className="text-muted d-block">{value.details}</span>
                            <span className="text-muted d-block">{value.times}</span>
                        </a>
                        </div>
                    </div>
                    {value.read_at == null?
                    <span className="recent-chat-status"><a href="javascript:void(0)">
                              <i className="fa-solid fa-circle main-color" /></a>
                          </span>
                        :''
                    }

                </div>
            </li>
            ))}
            </>
    )

export default React.memo(NotificationList)