import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '../assets/css/video-js-forest.css';
import { useDispatch, useSelector } from "react-redux";


const VideoJS = ({ options, onReady, enableScrollPause }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const selector = useSelector((state) => state.settingsReducer);
  const settingsData = selector?.data;


  useEffect(() => {
    if (enableScrollPause && settingsData.allowVideoAutoPlay) {
      const handleScroll = () => {
        const player = playerRef.current;
        if (!player || !videoRef.current) return;

        const rect = videoRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const isInViewport = rect.top >= 0 && rect.bottom <= windowHeight;

        if (isInViewport) {
          player.play().catch(error => {
            console.error('Failed to play the video', error);
          });
        } else {
          player.pause();
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [enableScrollPause]);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('w-100', 'vjs-theme-forest', 'rounded-3');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        if (onReady) {
          onReady(player);
        }
      });

      if(options.name){
        // Generate JSON-LD markup for the video
        const videoMarkup = {
          '@context': 'https://schema.org/',
          '@type': 'VideoObject',
          name: options.name,
          uploadDate: options.uploadDate,
          embedUrl: options.embedUrl,
          thumbnailUrl: options.poster,
          contentUrl: options.sources[0].src
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify(videoMarkup);
        document.head.appendChild(script);
      }

    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;

        if(options.name){
          const script = document.querySelector('script[type="application/ld+json"]');
          if (script) {
            script.remove();
          }
        }
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
